<template>
  <!--start summer-collection-->

  <section class="summer-collection" v-if="allData != ''">
    <div
      v-for="item in allData.slice(0, 1)"
      class="image_banner"
      :key="item.id"
      :style="'background-image:url(' + item.image + ')'"
    >
      <a
        :href="item.link"
        target="_blank"
        class="text_banner"
        v-if="item.link != null"
      >
        <!-- <img class="lazy img-responsive" :src="item.image" /> -->
        <h2>{{ item.title }}</h2>
        <h2>{{ item.desc }}</h2>
      </a>
      <router-link to="/" class="text_banner" v-else>
        <!-- <img class="lazy img-responsive" :src="item.image" /> -->
        <h2>{{ item.title }}</h2>
        <h2>{{ item.desc }}</h2>
      </router-link>
    </div>
  </section>

  <!--end summer-collection-->
</template>

<script>
import axios from "axios";
import Loader from "./../Loading/banner";
export default {
  data() {
    return {
      loading: false,
      allData: "",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      axios
        .get("/general/home", {
          headers: {
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then((res) => {
          this.loading = false;
          this.allData = res.data.data[0].large_banners;
        })
        .finally(() => (this.loading = false));
    },
  },
  components: {
    Loader,
  },
};
</script>
