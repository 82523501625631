<template>
  <div>
    <!--start page-banner-->

    <section class="page-banner">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <!--breadcrumbs-->
            <div class="breadcrumbs">
              <h3>{{ mainText.footer.menu.faq }}</h3>
              <ul class="list-unstyled">
                <li>
                  <router-link to="/">{{ mainText.home.title }} </router-link>>
                </li>
                <li>{{ mainText.footer.menu.faq }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--end page-banner-->

    <!--start shipping-->

    <section class="faqs">
      <div class="container">
        <Loader v-if="loading" class="fadeIn"></Loader>
        <div class="row" v-else>
          <NotFound v-if="allData.common_questions == ''"></NotFound>
          <div class="col-sm-12 wow fadeIn" v-else>
            <div id="accordion1">
              <div class="row">
                <div class="col-sm-12">
                  <div class="main-title">
                    <h3>{{ mainText.footer.menu.faq }}</h3>
                  </div>
                </div>
              </div>
              <div
                class="card"
                v-for="faq in allData.common_questions"
                :key="faq.id"
              >
                <div class="card-header" :id="'heading' + faq.id">
                  <h5 class="mb-0">
                    {{ faq.question }}
                    <button
                      class="btn btn-link"
                      data-toggle="collapse"
                      :data-target="'#collapse' + faq.id"
                      aria-expanded="true"
                      :aria-controls="'collapse' + faq.id"
                    >
                      <i class="fas fa-angle-down"></i>
                    </button>
                  </h5>
                </div>

                <div
                  :id="'collapse' + faq.id"
                  class="collapse"
                  :aria-labelledby="'heading' + faq.id"
                  data-parent="#accordion1"
                >
                  <div class="card-body" v-html="faq.answer"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--end shipping-->
  </div>
</template>

<script>
import axios from "axios";
import textAr from "../lang/ar.json";
import textEn from "../lang/en.json";
import NotFound from "../Loading/not-found";
import Loader from "./../Loading/Loading";
export default {
  data() {
    return {
      allData: "",
      loading: false,
      lang: localStorage.getItem("shop_lang"),
      mainText: "",
    };
  },

  methods: {
    getData() {
      this.loading = true;
      axios
        .get("general/settings", {
          headers: {
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then((res) => {
          this.loading = false;
          this.allData = res.data.data;
        })
        .finally(() => (this.loading = false));
    },
  },
  components: {
    NotFound,
    Loader,
  },
  mounted() {
    this.getData();
    if (this.lang == "en") {
      this.mainText = textEn;
    } else {
      this.mainText = textAr;
    }
  },
};
</script>
