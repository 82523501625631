import Vue from "vue";
import Router from "vue-router";
import Home from "../components/pages/Home.vue";
import MainHome from "../components/pages/home/MainHome.vue";
//products
import AllCategories from "../components/pages/Products/AllCategories.vue";
import Category from "../components/pages/Products/Category.vue";
import BestOffers from "../components/pages/Products/BestOffers.vue";
import BestSelling from "../components/pages/Products/bestSelling.vue";
import LowestPrice from "../components/pages/Products/LowestPrice.vue";
import Auctions from "../components/pages/Products/Auctions.vue";
import ExclusiveProducts from "../components/pages/Products/exclusiveProducts.vue";
import SingleProduct from "../components/pages/Products/SingleProduct/SingleProduct.vue";
import SingleProductAuctions from "../components/pages/Products/SingleProduct/SingleProductAucations.vue";
//Auth
import Login from "../components/pages/UserAuth/Login/Login.vue";
import SignUp from "../components/pages/UserAuth/SignUp/SignUp.vue";
//forget pass
import EnterEmail from "../components/pages/UserAuth/ForgetPassword/EnterEmail.vue";
import EnterCode from "../components/pages/UserAuth/ForgetPassword/EnterCode.vue";
import ChangePassword from "../components/pages/UserAuth/ForgetPassword/ChangePassword.vue";
//static pages
import AboutUs from "../components/pages/StaticPages/AboutUs.vue";
import TheBlog from "../components/pages/StaticPages/TheBlog.vue";
import BlogArticle from "../components/pages/StaticPages/BlogArticle.vue";
import ContactUs from "../components/pages/StaticPages/ContactUs.vue";
import Faqs from "../components/pages/StaticPages/Faqs.vue";
import Shipping from "../components/pages/StaticPages/Shipping.vue";
import PrivacyPolicy from "../components/pages/StaticPages/PrivacyPolicy.vue";
import TermsConditions from "../components/pages/StaticPages/TermsConditions.vue";
import TermsOfSell from "../components/pages/StaticPages/TermsOfSell.vue";
import TermsOfUse from "../components/pages/StaticPages/TermsOfUse.vue";
import Vat from "../components/pages/StaticPages/Vat.vue";
import ReturnProduct from "../components/pages/StaticPages/ReturnProduct.vue";
import OrderTracking from "../components/pages/StaticPages/OrderTracking.vue";
import subCategory from "../components/pages/Products/sub-category.vue";
//my account
//home
import MyAccount from "../components/pages/Profile/Home.vue";
//personal info
import PersonalInfo from "../components/pages/Profile/PersonalInfo/PersonalInfo.vue";
//edit password
import EditPassword from "../components/pages/Profile/AccountDetails/EditPassword.vue";
//account-details
import AccountDetails from "../components/pages/Profile/AccountDetails/AccountDetails.vue";
//orders
import Orders from "../components/pages/Profile/Orders/Orders.vue";
import OrderDetails from "../components/pages/Profile/Orders/OrderDetails.vue";
import myAuctions from "../components/pages/Profile/myAuctions/my-auctions.vue";
//addresses
import Addresses from "../components/pages/Profile/Addresses/Addresses.vue";
import AddAddress from "../components/pages/Profile/Addresses/AddAddress.vue";
import EditAddress from "../components/pages/Profile/Addresses/EditAddress.vue";
//cards
import Cards from "../components/pages/Profile/Cards/Cards.vue";
import AddCard from "../components/pages/Profile/Cards/AddCard.vue";
import EditCard from "../components/pages/Profile/Cards/EditCard.vue";

//checkout
import Checkout from "../components/pages/Checkout/Checkout.vue";
import AcceptOrder from "../components/pages/Checkout/OrderAccept.vue";

//Pay
import PaySuccess from "../components/pages/StaticPages/PaySuccess.vue";
import PayCancel from "../components/pages/StaticPages/PayCancel.vue";

//search
import Search from "../components/pages/Search/Search.vue";

Vue.use(Router);

const routes = [
  {
    path: "/",
    component: Home,
    children: [
      {
        path: "/",
        name: "main-home",
        component: MainHome,
      },
      {
        path: "/all-categories",
        name: "all-categories",
        component: AllCategories,
      },
      {
        path: "/category/:id",
        name: "category",
        component: Category,
      },
      {
        path: "/category/:main_id/sub-category/:sub_id",
        name: "subCategory",
        component: subCategory,
      },
      {
        path: "/product/:id",
        name: "product",
        component: SingleProduct,
      }, {
        path: "/auction/:id",
        name: "auction",
        component: SingleProductAuctions,
      },
      {
        path: "/best-offers",
        name: "best-offers",
        component: BestOffers,
      },
      {
        path: "/best-selling",
        name: "best-selling",
        component: BestSelling,
      }, {
        path: "/lowest-price",
        name: "lowest-price",
        component: LowestPrice,
      }, {
        path: "/all-auctions",
        name: "all-auctions",
        component: Auctions,
      },
      {
        path: "/new-arrivals",
        name: "exclusive-products",
        component: ExclusiveProducts,
      },
      {
        path: "/checkout",
        name: "checkout",
        component: Checkout,
      },
      {
        path: "/order-accept",
        name: "order-accept",
        component: AcceptOrder,
      },

      //static pages
      {
        path: "/about-us",
        name: "about-us",
        component: AboutUs,
      },
      {
        path: "/blog",
        name: "TheBlog",
        component: TheBlog,
      },
      {
        path: "/blog/:id",
        name: "BlogArticle",
        component: BlogArticle,
      },
      {
        path: "/contact-us",
        name: "contact-us",
        component: ContactUs,
      },
      {
        path: "/faqs",
        name: "faqs",
        component: Faqs,
      },
      {
        path: "/shipping",
        name: "shipping",
        component: Shipping,
      },
      {
        path: "/privacy-policy",
        name: "privacy-policy",
        component: PrivacyPolicy,
      },
      {
        path: "/terms-conditions",
        name: "terms-conditions",
        component: TermsConditions,
      },
      {
        path: "/terms-of-sell",
        name: "terms-of-sell",
        component: TermsOfSell,
      },
      {
        path: "/terms-of-use",
        name: "terms-of-use",
        component: TermsOfUse,
      },
      {
        path: "/vat",
        name: "vat",
        component: Vat,
      },
      {
        path: "/return-product",
        name: "return-product",
        component: ReturnProduct,
      },
      {
        path: "/order-tracking",
        name: "order-tracking",
        component: OrderTracking,
      },
      {
        path: "/search/:text",
        name: "search",
        component: Search,
      },
      {
        path: "/pay_success",
        name: "PaySuccess",
        component: PaySuccess,
      },
      {
        path: "/pay_cancel",
        name: "PayCancel",
        component: PayCancel,
      },
    ],
  },
  //Auth
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/sign-up",
    name: "sign-up",
    component: SignUp,
  },
  {
    path: "/enter-phone",
    name: "enter-email",
    component: EnterEmail,
  },
  {
    path: "/enter-code",
    name: "enter-code",
    component: EnterCode,
  },
  {
    path: "/change-password",
    name: "change-password",
    component: ChangePassword,
  },

  //profile
  {
    path: "/my-account",
    component: MyAccount,
    children: [
      {
        path: "/my-account",
        name: "my-account",
        component: PersonalInfo,
      },
      {
        path: "/my-account",
        name: "my-account",
        component: PersonalInfo,
      },
      {
        path: "/account-details",
        name: "account-details",
        component: AccountDetails,
      },
      {
        path: "/edit-password",
        name: "edit-password",
        component: EditPassword,
      },
      {
        path: "/orders",
        name: "orders",
        component: Orders,
      },{
        path: "/my-auctions",
        name: "my-auctions",
        component: myAuctions,
      },
      {
        path: "/order-details/:id",
        name: "order-details",
        component: OrderDetails,
      },
      {
        path: "/addresses",
        name: "addresses",
        component: Addresses,
      },
      {
        path: "/add-address",
        name: "add-addresses",
        component: AddAddress,
      },
      {
        path: "/edit-address/:id",
        name: "edit-addresses",
        component: EditAddress,
      },
      {
        path: "/cards",
        name: "cards",
        component: Cards,
      },
      {
        path: "/add-card",
        name: "add-card",
        component: AddCard,
      },
      {
        path: "/edit-card",
        name: "edit-card",
        component: EditCard,
      },
    ],
  },
  {
    path: "*",
    redirect: "/",
  },
];

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    document.getElementById("app").scrollIntoView({ behavior: "smooth" });
  },
  routes,
});
