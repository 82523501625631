<template>
  <div id="app">
    <!-- START:: SPLASH LOADER -->
    <!-- <transition name="fade" mode="out-in" v-if="splashLoader">
      <div class="splash_screen">
        <transition name="bounce" v-if="splashLogo">
          <img src="@/assets/images/logo.png" />
        </transition>
      </div>
    </transition> -->
    <!-- END:: SPLASH LOADER -->
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      splashLoader: true,
      splashLogo: true,
    };
  },
  // START:: VUE-META
  // metaInfo: {
  //   title: "Inflco",
  //   meta: [
  //     {
  //       name: "description",
  //       content: "Starter App Description Will Go Here",
  //     },
  //   ],
  // },
  // END:: VUE-META
  methods: {
    // loaderSplash() {
    //   setTimeout(() => {
    //     this.splashLoader = false;
    //   }, 3500);
    //   setTimeout(() => {
    //     this.splashLogo = false;
    //   }, 3000);
    // },
  },

  created() {
    var body = document.querySelector("body");
    var lang = localStorage.getItem("shop_lang");
    if (lang == "en") {
      body.classList.add("ltr");
    } else {
      body.classList.remove("ltr");
      body.classList.add("rtl");
    }
    if (!lang) {
      localStorage.setItem("shop_lang", "en");
    }
  },
  mounted() {
    // this.loaderSplash();
    setTimeout(() => {
      document
        .getElementById("main-app")
        .scrollIntoView({ behavior: "smooth" });
    }, 4000);
  },
};
</script>
