<template>
    <div>
      <!--start page-banner-->
  
      <section class="page-banner">
        <div class="container">
          <div class="row">
            <div class="col-sm-12">
              <!--breadcrumbs-->
              <div class="breadcrumbs">
                <h3 v-if="lang == 'en'">Pay Canceled</h3>
                <h3 v-if="lang == 'ar'">لم يتم الدفع</h3>
                <ul class="list-unstyled">
                  <li v-if="lang == 'en'">
                    <router-link to="/">Home </router-link>>
                  </li>
                  <li v-if="lang == 'ar'">
                    <router-link to="/">الرئيسية </router-link>>
                  </li>
                  <li v-if="lang == 'en'">Pay Canceled</li>
                  <li v-if="lang == 'ar'">لم يتم الدفع</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
  
      <!--end page-banner-->
      <!--start shipping-->
  
      <section class="shipping">
        <div class="container">
          <!-- <Loader v-if="loading" class="fadeIn"></Loader> -->
          <div class="all-text">
            <!-- <NotFound v-if="allData == ''"></NotFound> -->
            <div class="row">
              <div class="col-sm-12 fadeIn">
                <div class="pay">
                  <span class="icon"><i class="fas fa-times"></i></span>
                  <h4 v-if="lang == 'ar'">لم يتم الدفع</h4>
                  <h4 v-if="lang == 'en'">Pay Canceled</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  
      <!--end shipping-->
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import Loader from "./../Loading/Loading";
  import NotFound from "../Loading/not-found";
  export default {
    data() {
      return {
        allData: "",
        loading: false,
        lang: localStorage.getItem("shop_lang"),
        mainText: "",
        shipping_background: "",
      };
    },
  
    methods: {
      getData() {
        this.loading = true;
        axios
          .get("general/settings", {
            headers: {
              "cache-control": "no-cache",
              "Content-type": "application/json",
              Accept: "application/json",
              lang: localStorage.getItem("shop_lang"),
            },
          })
          .then((res) => {
            this.loading = false;
            this.allData = res.data.data.shipping;
            this.shipping_background = res.data.data.shipping_background;
          })
          .finally(() => (this.loading = false));
      },
    },
    mounted() {
      //   this.getData();
    },
    components: {
      Loader,
      NotFound,
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .pay {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    span.icon {
      width: 85px;
      height: 85px;
      border-radius: 50%;
      background-color: azure;
      color: #f00;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 35px;
    }
    h4 {
      font-size: 35px;
    }
  }
  </style>
  