<template>
  <div>
    <section class="product-description" v-if="mainText">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="bottom-part">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="rating-tab"
                    data-toggle="tab"
                    href="#rating"
                    role="tab"
                    aria-controls="rating"
                    aria-selected="false"
                    >{{ mainText.product.details.rating }}</a
                  >
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div
                  class="tab-pane fade show active"
                  id="rating"
                  role="tabpanel"
                  aria-labelledby="rating-tab"
                >
                  <div class="overall-rate">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-4">
                            <div class="overall-num">
                              <p v-if="lang === 'en'">OVERALL RATING</p>
                              <p v-else>كل التقييمات</p>
                              <span>{{ rate }}</span>
                              <div class="ov-rating">
                                <ul class="list-unstyled">
                                  <li
                                    class="full-star"
                                    v-for="star in rate"
                                    :key="star.id"
                                  >
                                    <span><i class="fas fa-star"></i></span>
                                  </li>
                                  <li
                                    class="gray-star"
                                    v-for="star in stars - rate"
                                    :key="star.id"
                                  >
                                    <span><i class="fas fa-star"></i></span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-8"></div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="add-rate">
                          <a href="#add-rate">{{
                            mainText.product.details.addRate
                          }}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div
                      class="col-sm-12"
                      v-for="review in allData.reviews"
                      :key="review.id"
                    >
                      <div class="rating-box">
                        <div class="media">
                          <img
                            :src="review.avatar"
                            alt="Generic placeholder image"
                          />
                          <div class="media-body">
                            <h5 class="mt-0">{{ review.name }}</h5>
                            <div class="user-rating">
                              <ul class="list-unstyled">
                                <li
                                  class="full-star"
                                  v-for="star in review.rate"
                                  :key="star.id"
                                >
                                  <span><i class="fas fa-star"></i></span>
                                </li>
                                <li
                                  class="gray-star"
                                  v-for="star in stars - review.rate"
                                  :key="star.id"
                                >
                                  <span><i class="fas fa-star"></i></span>
                                </li>
                              </ul>
                            </div>
                            <p>
                              {{ review.comment }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- error alert -->
        <transition name="slide-fade">
          <div class="error-alert" v-if="error.static.error">
            <span @click="error.static.error = false">
              <i class="fas fa-times"></i>
            </span>
            <p>{{ error.static.text }}</p>
          </div>
        </transition>
        <!-- success alert -->
        <transition name="slide-fade">
          <div class="success-alert" v-if="success">
            <span @click="success = false"> <i class="fas fa-times"></i> </span>
            <p>{{ mainText.addRate.done }}</p>
          </div>
        </transition>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import textAr from "../../lang/ar.json";
import textEn from "../../lang/en.json";
export default {
  data() {
    return {
      allData: "",
      id: this.$route.params.id,
      rate: "",
      stars: 5,
      progress_rate: [],
      revData: {
        product_id: "",
        rate: "",
        comment: "",
      },
      lang: localStorage.getItem("shop_lang"),
      token: localStorage.getItem("shop-token"),
      mainText: "",
      waiting: false,
      error: {
        errorEmail: false,
        errorPassword: false,
        static: {
          error: false,
          text: "",
        },
      },
      success: false,
    };
  },
  methods: {
    getData() {
      this.loading = true;
      //  let productID =
      axios
        .get("/general/auctionDetails/" + this.id, {
          headers: {
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then((res) => {
          this.loading = false;
          this.allData = res.data.data.product;
          this.rate = parseInt(res.data.data.product.rate);
        })
        .finally(() => (this.loading = false));
    },
  },
  mounted() {
    // this.getData();
    this.getData();
    if (this.lang == "en") {
      this.mainText = textEn;
    } else {
      this.mainText = textAr;
    }
  },
};
</script>
