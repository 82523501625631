import Vue from "vue";
import App from "./App.vue";
import router from "./router";

// vue resource
import VueResource from "vue-resource";
//bootstrap
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

/*fontawesome*/
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";

/*style*/
import "../src/assets/css/style.css";
import "../src/assets/css/style-rtl.css";
import "../src/assets/css/responsive.css";

// baseurl
import axios from "axios";
// socail login
// import firebase from "firebase/app";
// let app = "";
// firebase.initializeApp({
//   apiKey: "AIzaSyBRj58vTb9rVJbfG3G2nzN6ELZhm01vQg4",
//   authDomain: "davina-24a38.firebaseapp.com",
//   databaseURL: "https://davina-24a38.firebaseio.com",
//   projectId: "davina-24a38",
//   storageBucket: "davina-24a38.appspot.com",
//   messagingSenderId: "559212416460",
//   appId: "1:559212416460:web:3482dd04c34c2fddf3a169",
//   // measurementId: "G-XWJW5MCDL8",
// });
// firebase.initializeApp(firebaseConfig);
// firebase.initializeApp();
import VueSocialSharing from "vue-social-sharing";
Vue.use(VueSocialSharing);

// Slider

import VueLazyload from "vue-lazyload";
import loaderGIF from "./assets/loader.gif";
// Vue.use(VueLazyload)
// or with options
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: loaderGIF,
  loading: loaderGIF,
  attempt: 1,
});
// Zoom Images
import VueMeta from "vue-meta";

Vue.use(VueMeta);

import ZoomOnHover from "vue-zoom-on-hover";
Vue.use(ZoomOnHover);
// map
import * as VueGoogleMaps from "vue2-google-maps";

import store from "./store/index";

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDx--BILIM2LjZBxVMjGYVb8YqrY-Vk_Yk",
    libraries: "places",
  },
});
axios.defaults.baseURL = "https://backend.knooz-global.com/api/";
Vue.config.productionTip = false;
Vue.use(VueResource);
// loading
var eventBus = new Vue(
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app")
);
Vue.prototype.$eventBus = eventBus;
// firebase.auth().onAuthStateChanged(() => {
//   if (!app) {
//     app = new Vue({
//       router,
//       store,
//       render: (h) => h(App),
//     }).$mount("#app");
//   }
// });
