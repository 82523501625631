<template>
  <!--start Best Selling-->
  <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
  <section class="best-selling products">
    <div class="container">
      <div class="main-title" v-if="mainText">
        <h3>{{ mainText.home.titles.exclusiveProducts }}</h3>
      </div>
      <Loader v-if="$store.state.cardsLoading" class="fadeIn"></Loader>
      <div class="row fadeIn" v-else>
        <NotFound
          v-if="$store.state.homeData.recommended_products == ''"
        ></NotFound>
        <!-- end of tabs links-->
        <div class="col-sm-12" v-else>
          <!-- tabs cotent -->
          <div class="best-carousel">
            <VueSlickCarousel
              v-bind="slider2Setting"
              @beforeChange="onBeforeChangeSlider1"
              class="bes-car"
            >
              <div
                class="item"
                v-for="item in $store.state.homeData.recommended_products"
                :key="item.id"
              >
                <div class="one-product">
                  <!-- product image-->
                  <router-link :to="'/product/' + item.id">
                    <div class="product-img">
                      <img v-lazy="item.main_image" />
                      <img class="hover_image" v-lazy="item.hover_image" />
                    </div>
                  </router-link>
                  <!-- product description -->
                  <div class="product-exp">
                    <!-- product title -->
                    <div class="product-title">
                      <h5>{{ item.name }}</h5>
                      <span> {{ item.brand_name }} </span>
                    </div>
                    <!-- product add , show and like -->
                    <div class="price-fav">
                      <div class="price">
                        <span class="new-price" v-if="item.is_offered != 0"
                          ><span class="currency"></span>
                          {{ item.offer_price }}</span
                        >
                        <span class="new-price" v-if="item.is_offered == 0"
                          ><span class="currency"></span> {{ item.price }}</span
                        >
                        <span class="old-price" v-if="item.is_offered != 0"
                          ><span class="currency"></span> {{ item.price }}</span
                        >
                      </div>
                      <ul class="list-unstyled" v-if="token">
                        <li class="like">
                          <button
                            @click="
                              $set(item, 'isActive', !item.isActive),
                                doLike(item.id)
                            "
                            v-if="item.is_favourite == false"
                            :class="[
                              'btn',
                              { likee: item.isActive },
                              { dislikee: !item.isActive },
                            ]"
                          ></button>
                          <!-- like -->
                          <button
                            @click="
                              $set(item, 'isActive', !item.isActive),
                                doLike(item.id)
                            "
                            v-else
                            :class="[
                              'btn',
                              { likee: !item.isActive },
                              { dislikee: item.isActive },
                            ]"
                          ></button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <span class="pro-stat" v-if="item.is_offered != 0">
                    <span v-if="lang == 'en'">Sale !</span>
                    <span v-else>تخفيض !</span>
                  </span>
                </div>
                <div class="text-center">
                  <router-link
                    :to="'/product/' + item.id"
                    class="dark-button button btn main-btn home-btn"
                  >
                    <span v-if="lang == 'en'">Select Option</span>
                    <span v-if="lang == 'ar'">حدد خيار</span>
                  </router-link>
                </div>
              </div>
            </VueSlickCarousel>
          </div>
        </div>
        <!-- show more products -->
        <div
          class="col-sm-12"
          v-if="$store.state.homeData.recommended_products !== '' && mainText"
        >
          <div class="show-more">
            <router-link to="/new-arrivals">
              {{ mainText.home.seeAll }}
              <span><img src="@/assets/images/long-arrow.png" /></span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--end Best-selling-->
</template>

<script>
import Loader from "./../Loading/cards";
import textAr from "../lang/ar.json";
import textEn from "../lang/en.json";
import NotFound from "./../Loading/not-found";
// import carousel from "vue-owl-carousel";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
export default {
  data() {
    return {
      allData: "",
      token: localStorage.getItem("shop-token"),
      lang: localStorage.getItem("shop_lang"),
      mainText: "",
      loading: false,
      slider2Setting: {
        rows: 2,
        dots: false,
        arrows: true,
        infinite: false,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 4,
        slidesPerRow: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              rows: 1,
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              rows: 1,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              rows: 1,
            }
          }
        ]
      },
    };
  },
  mounted() {
    if (this.lang == "en") {
      this.mainText = textEn;
    } else {
      this.mainText = textAr;
    }
  },
  methods: {
    onBeforeChangeSlider1(currentPosition, nextPosition) {},
    // add to favourite
    doLike(id) {
      this.$store.dispatch("addFavourite", { id });
    },
  },
  components: {
    Loader,
    NotFound,
    VueSlickCarousel,
  },
};
</script>
