<template>
  <!--start banner-->

  <section class="categories_section mt-5">
    <div class="main-title" v-if="mainText">
      <h3>{{ mainText.home.titles.categories }}</h3>
    </div>
    <CategoriesLoader v-if="loading" class="fadeIn"></CategoriesLoader>
    <div v-else class="container">
      <div class="all-categories row">
        <div class="col-md-3" v-for="category in allData" :key="category.id">
          <router-link :to="'/category/' + category.id">
            <div class="single-category">
              <div class="image_box">
                <img :src="category.image" :alt="category.name" />
              </div>
              <h3 class="title-category">
                {{ category.name }}
              </h3>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </section>

  <!--end banner-->
</template>
<script>
import axios from "axios";
import CategoriesLoader from "./../Loading/CategoriesLoader";
import textAr from "../lang/ar.json";
import textEn from "../lang/en.json";
export default {
  data() {
    return {
      loading: false,
      allData: "",
      lang: localStorage.getItem("shop_lang"),
      mainText: "",
    };
  },
  mounted() {
    this.getData();
    if (this.lang == "en") {
      this.mainText = textEn;
    } else {
      this.mainText = textAr;
    }
  },
  methods: {
    getData() {
      this.loading = true;
      axios
        .get("/general/home", {
          headers: {
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then((res) => {
          this.loading = false;
          this.allData = res.data.data[0].categories_with_image;
        })
        .finally(() => (this.loading = false));
    },
  },
  components: {
    CategoriesLoader,
  },
};
</script>
