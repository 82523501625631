<template>
  <!-- loader -->
  <div class="not-found fadeIn">
    <span class="icon">
      <i class="fas fa-exclamation-circle"></i>
    </span>
    <h3 v-if="lang == 'en'">Sorry There Is No Data Here</h3>
    <h3 v-if="lang == 'ar'">عفوا لا يوجد نتائج</h3>
  </div>
</template>

<script>
export default {
  name: "main-slider-loader",
  data() {
    return {
      lang: localStorage.getItem("shop_lang"),
    };
  },
  mounted() {},
  components: {},
};
</script>
<style scoped>
.not-found {
  text-align: center;
  width: 100%;
}
.not-found .icon {
  font-size: 70px;
  color: var(--thm-red);
  margin-bottom: 10px;
  display: block;
}
.not-found h3 {
  font-size: 25px;
  font-weight: bold;
  color: var(--thm-black);
}
</style>
