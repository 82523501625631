<template>
  <div>
    <!--start single-product-->

    <ProductSliderAucations></ProductSliderAucations>

    <!--end single-product-->

    <!--start product description-->

    <ProductDetailsAuctions></ProductDetailsAuctions>

    <!--end product description-->
  </div>
</template>

<script>
import ProductSliderAucations from "./ProductSliderAucations.vue";
import ProductDetailsAuctions from "./ProductDetailsAuctions.vue";
export default {
  data() {
    return {};
  },
  components: {
    ProductSliderAucations,
    ProductDetailsAuctions,
  },
};
</script>
