<template>
  <div class="staticPages blog_article">
    <!-- START:: ARTICLE SLIDER -->
    <div class="article_slider">
      <carousel
        :autoplay="true"
        class="owl-header fadeIn"
        :nav="false"
        :dots="false"
        :items="1"
        :loop="true"
        :center="true"
      >
        <div class="item" v-for="slide in articleData.images" :key="slide.id">
          <img :src="slide.image" class="d-block w-100" alt="Article image" />
        </div>
      </carousel>
    </div>
    <!-- END:: ARTICLE SLIDER -->

    <div class="container p-0 pt-5 pb-5">
      <div class="row">
        <!-- START:: ARTICLE BODY -->
        <div class="col-lg-8">
          <div class="article_body_wrapper">
            <div class="article_title">
              <h4>{{ articleData.name }}</h4>

              <div class="share_btn_wrapper">
                <button class="share_btn" @click="toggleShareBtns">
                  <span>
                    <Share2Icon size="1.6x" />
                  </span>
                </button>

                <transition name="fade" mode="out-in">
                  <ul class="share_btns" v-if="shareBtnsMenuIsOpen">
                    <li class="single_share_btn">
                      <button @click="toggleShareBtns">
                        <ShareNetwork
                          network="facebook"
                          :url="'https://knooz-global.com/' + this.$route.path"
                          :title="articleData.name"
                          :description="articleData.desc"
                          hashtags="Davina Store"
                        >
                          <img
                            src="../../../assets/social-icons/facebook.svg"
                            alt="Facebook"
                            width="30"
                            height="30"
                          />
                        </ShareNetwork>
                      </button>
                    </li>

                    <li class="single_share_btn">
                      <button @click="toggleShareBtns">
                        <ShareNetwork
                          network="twitter"
                          :url="'https://knooz-global.com/' + this.$route.path"
                          :title="articleData.name"
                          :description="articleData.desc"
                          hashtags="Davina"
                        >
                          <img
                            src="../../../assets/social-icons/Twitter.svg"
                            alt="Twitter"
                            width="30"
                            height="30"
                          />
                        </ShareNetwork>
                      </button>
                    </li>

                    <li class="single_share_btn">
                      <button @click="toggleShareBtns">
                        <ShareNetwork
                          network="whatsapp"
                          :url="'https://knooz-global.com/' + this.$route.path"
                          :title="articleData.name"
                          :description="articleData.desc"
                          hashtags="Davina"
                        >
                          <img
                            src="../../../assets/social-icons/whatsapp.svg"
                            alt="Twitter"
                            width="30"
                            height="30"
                          />
                        </ShareNetwork>
                      </button>
                    </li>
                  </ul>
                </transition>
              </div>
            </div>

            <div class="article_publish_date">
              <h6>{{ articleData.created_at }}</h6>
            </div>

            <div class="article_content" v-html="articleData.desc"></div>
          </div>
        </div>
        <!-- END:: ARTICLE BODY -->

        <!-- START:: RELATED ARTICLES -->
        <div class="col-lg-4">
          <div class="related_articles_wrapper">
            <div class="title_wrapper">
              <h4 v-if="lang == 'en'">Related Blogs</h4>
              <h4 v-else>المدونات ذات الصلة</h4>
            </div>

            <!-- START:: RELATED ARTICLE CARD -->
            <router-link
              v-for="relatedArticle in articleData.related_blogs"
              :key="relatedArticle.id"
              class="related_article_wrapper"
              :to="'/blog/' + relatedArticle.id"
            >
              <div class="image_wrapper">
                <img
                  :src="relatedArticle.main_image"
                  alt="Article Image"
                  width="100"
                  height="100"
                />
              </div>

              <div class="related_blog_title">
                <h5>{{ relatedArticle.name }}</h5>
                <div class="publish_date_wrapper">
                  {{ relatedArticle.created_at }}
                </div>
              </div>
            </router-link>
            <!-- END:: RELATED ARTICLE CARD -->
          </div>
        </div>
        <!-- END:: RELATED ARTICLES -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import { Share2Icon } from "vue-feather-icons";

import carousel from "vue-owl-carousel2";

export default {
  name: "BlogArticle",

  // START:: VUE-META
  metaInfo() {
    return {
      title: "Davina/Blog/Article",
      meta: [
        {
          name: "description",
          content: this.metaDescription,
        },
      ],
    };
  },
  // END:: VUE-META

  components: {
    Share2Icon,
    carousel,
  },

  data() {
    return {
      // START:: APP LANG DATA
      lang: localStorage.getItem("shop_lang"),
      // END:: APP LANG DATA

      // START:: ARTICLE ID DATA
      articleId: this.$route.params.id,
      // END:: ARTICLE ID DATA

      // START:: SHARE BTNS VISIBILITY DATA
      shareBtnsMenuIsOpen: false,
      // END:: SHARE BTNS VISIBILITY DATA

      // START:: ARTICLE DATA
      articleData: null,
      metaDescription: "Description Meta",
      // END:: ARTICLE DATA
    };
  },

  methods: {
    // START:: TOGGLE SHARE BTNS METHOD
    toggleShareBtns() {
      this.shareBtnsMenuIsOpen = !this.shareBtnsMenuIsOpen;
    },
    // END:: TOGGLE SHARE BTNS METHOD

    // START:: GET ARTICLE DETAILS
    getArticleDetails() {
      axios
        .get(`/general/blogs/${this.articleId}`, {
          headers: {
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then((res) => {
          this.articleData = res.data.data;
          this.metaDescription = res.data.data.meta;
        })
        .catch((error) => {
          console.error(error.data);
        });
    },
    // END:: GET ARTICLE DETAILS
  },

  mounted() {
    this.getArticleDetails();
  },
};
</script>
