<template>
  <div class="staticPages blog">
    <!-- START:: TITLE -->
    <div class="page_title">
      <h1 v-if="lang == 'en'">Check Out Our Blogs!</h1>
      <h1 v-else>إتطلع على مدوناتنا!</h1>
    </div>
    <!-- END:: TITLE -->

    <!-- START:: BLOG CARDS -->
    <div class="container">
      <div class="row justify-content-center">
        <!-- START:: BLOG CARD WRAPPER -->
        <div class="col-lg-4 my-2 px-5" v-for="blog in blogData" :key="blog">
          <router-link class="article_route" :to="'/blog/' + blog.id">
            <div class="blog_card_wrapper">
              <div class="img_wrapper">
                <img
                  :src="blog.main_image"
                  alt="Article Image"
                  width="150"
                  height="150"
                />
              </div>

              <div class="article_name_wrapper">
                <h5>
                  {{ blog.name }}
                </h5>
              </div>

              <!-- <div class="article_desc_wrapper">
                <h5 v-html="blog.desc"></h5>
              </div> -->

              <div class="publish_date_wrapper">
                <h6>{{ blog.created_at }}</h6>
              </div>
            </div>
          </router-link>
        </div>
        <!-- END:: BLOG CARD WRAPPER -->
      </div>
    </div>
    <!-- END:: BLOG CARDS -->
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "TheBlog",

  data() {
    return {
      // START:: APP LANG DATA
      lang: localStorage.getItem("shop_lang"),
      // END:: APP LANG DATA

      // START:: BLOG DATA
      blogData: null,
      // END:: BLOG DATA
    };
  },

  methods: {
    // START:: GET BLOG DATA METHOD
    getBlogData() {
      axios
        .get("/general/blogs", {
          headers: {
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then((res) => {
          this.blogData = res.data.data;
        })
        .catch((error) => {
          console.error(error.data);
        });
    },
    // END:: GET BLOG DATA METHOD
  },

  mounted() {
    this.getBlogData();
  },
};
</script>
