<template>
  <div>
    <!-- START:: MAIN SLIDER -->
    <MainSlider></MainSlider>
    <!-- END:: MAIN SLIDER -->

    <!-- START:: OPTIONS SECTION -->
    <Options></Options>
    <!-- END:: OPTIONS SECTION -->

    <!-- START:: CATEGORIES SECTION -->
    <Categories></Categories>
    <!-- END:: CATEGORIES SECTION -->

    <!-- START:: TOP RATED SECTION -->
    <!-- <TopRating v-if="this.$store.state.homeData.top_rated != null"></TopRating> -->
    <!-- START:: TOP RATED SECTION -->

    <!-- START:: NEW ARRIVALS SECTION -->
    <ExclusiveProducts></ExclusiveProducts>
    <!-- END:: NEW ARRIVALS SECTION -->

    <!-- START:: BANNERS SECTION -->
    <!-- <Banners v-if="this.$store.state.homeData.advertisements != null"></Banners> -->
    <!-- END:: BANNERS SECTION -->

    <!-- START:: BANNERS SECTION -->
    <LowestPrice></LowestPrice>
    <!-- END:: BANNERS SECTION -->

    <!-- START:: SUMMER COLLECTION SECTION -->
    <SummerCollection></SummerCollection>
    <!-- END:: SUMMER COLLECTION SECTION -->
    
    
    <!-- <ClientOpinion v-if="this.$store.state.homeData.our_client_say != null"></ClientOpinion> -->

    <!-- START:: PARTNERS SECTION -->
    <!-- <Partners></Partners> -->
    <!-- END:: PARTNERS SECTION -->

    <!-- START:: NEWSLETTER SECTION -->
    <!-- <NewsLetter></NewsLetter> -->
    <!-- END:: NEWSLETTER SECTION -->
  </div>
</template>

<script>
import MainSlider from "./MainSlider";
// import TopRating from "./topRating";
// import Banners from "./Banners";
import LowestPrice from "./LowestPrice";
import ExclusiveProducts from "./ExclusiveProducts";
// import ClientOpinion from "./ClientOpinion";
import Partners from "./Partners";

import SummerCollection from "./SummerCollection.vue";
import NewsLetter from "./NewsLetter.vue";
import Categories from "./Categories.vue";
import Options from "./Options.vue";

import textAr from "../lang/ar.json";
import textEn from "../lang/en.json";
export default {
  data() {
    return {
      // splashLoader: true,
      lang: localStorage.getItem("shop_lang"),
      mainText: "",
    };
  },
  components: {
    MainSlider,
    // TopRating,
    // Banners,
    LowestPrice,
    ExclusiveProducts,
    // ClientOpinion,
    // Partners,
    
    SummerCollection,
    // NewsLetter,
    Categories,
    Options,
  },
  methods: {},
  mounted() {
    // START:: GET HOME DATA
    this.$store.dispatch("getHomeData");
    // END:: GET HOME DATA
    if (this.lang == "en") {
      this.mainText = textEn;
    } else {
      this.mainText = textAr;
    }
  },
};
</script>
