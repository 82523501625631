<template>
  <!--start Best Selling-->
  <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
  <div>
    <!--start page-banner-->
    <div class="page-banner">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <!--breadcrumbs-->
            <div class="breadcrumbs">
              <h3 v-if="lang == 'en'">Best Selling</h3>
              <h3 v-if="lang == 'ar'">الاكثر مبيعا</h3>
              <ul class="list-unstyled">
                <li v-if="lang == 'en'">
                  <router-link to="/" tag="a">Home </router-link> >
                </li>
                <li v-if="lang == 'ar'">
                  <router-link to="/" tag="a">الرئيسية </router-link> >
                </li>
                <li v-if="lang == 'en'">Best Selling</li>
                <li v-if="lang == 'ar'">الاكثر مبيعا</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="exclusive-products products">
      <div class="container">
        <Loader v-if="$store.state.cardsLoading"></Loader>
        <div class="row fadeIn" v-else>
          <!-- tabs links -->
          <div class="col-sm-12">
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li class="nav-item">
                <button
                  type="button"
                  class="nav-link btn"
                  :class="{ active: search === 'all' }"
                  @click="select_text('all')"
                >
                  All
                </button>
              </li>
              <li class="nav-item" v-for="item in allCategories" :key="item.id">
                <button
                  type="button"
                  class="nav-link btn"
                  :class="{ active: search === item.name }"
                  @click="select_text(item.name)"
                >
                  {{ item.name }}
                </button>
              </li>
            </ul>
          </div>

          <!-- end of tabs links-->
          <div class="col-sm-12">
            <!-- tabs cotent -->
            <div class="tab-content">
              <!-- tab cotent -->
              <div class="row">
                <div class="col-lg-12 fadeIn" v-if="filteredItems == ''">
                  <NotFound></NotFound>
                </div>
                <div
                  class="col-6 col-lg-3 col-md-6 fadeIn"
                  v-for="item in filteredItems"
                  :key="item.id"
                  v-else
                >
                  <div class="one-product">
                    <!-- product image-->
                    <router-link :to="'/product/' + item.id">
                      <div class="product-img">
                        <img v-lazy="item.main_image" />
                        <img class="hover_image" v-lazy="item.hover_image" />
                      </div>
                    </router-link>
                    <!-- product description -->
                    <div class="product-exp">
                      <!-- product title -->
                      <div class="product-title">
                        <h5>{{ item.name }}</h5>
                        <span>{{ item.brand_name }}</span>
                      </div>
                      <!-- product add , show and like -->
                      <div class="price-fav">
                        <div class="price">
                          <span class="new-price" v-if="item.is_offered != 0"
                            ><span class="currency"></span>
                            {{ item.offer_price }}</span
                          >
                          <span class="new-price" v-if="item.is_offered == 0"
                            ><span class="currency"></span>
                            {{ item.price }}</span
                          >
                          <span class="old-price" v-if="item.is_offered != 0"
                            ><span class="currency"></span>
                            {{ item.price }}</span
                          >
                        </div>
                        <ul class="list-unstyled" v-if="token">
                          <li class="like">
                            <button
                              @click="
                                $set(item, 'isActive', !item.isActive),
                                  doLike(item.id)
                              "
                              v-if="item.is_favourite == false"
                              :class="[
                                'btn',
                                { likee: item.isActive },
                                { dislikee: !item.isActive },
                              ]"
                            ></button>
                            <!-- like -->
                            <button
                              @click="
                                $set(item, 'isActive', !item.isActive),
                                  doLike(item.id)
                              "
                              v-else
                              :class="[
                                'btn',
                                { likee: !item.isActive },
                                { dislikee: item.isActive },
                              ]"
                            ></button>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <span class="pro-stat" v-if="item.is_offered != 0">
                      <span v-if="lang == 'en'">Sale !</span>
                      <span v-else>تخفيض !</span>
                    </span>
                  </div>
                  <div class="text-center mb-5">
                    <router-link
                      :to="'/product/' + item.id"
                      class="dark-button button btn main-btn home-btn"
                    >
                      <span v-if="lang == 'en'">Select Option</span>
                      <span v-if="lang == 'ar'">حدد خيار</span>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--end Best-selling-->
</template>

<script>
import axios from "axios";
import Loader from "./../Loading/cards";
import NotFound from "../Loading/not-found.vue";
export default {
  data() {
    return {
      search: "all",
      loading: false,
      // $store.state.allBestSelling: "",
      allCategories: "",
      token: localStorage.getItem("shop-token"),
      lang: localStorage.getItem("shop_lang"),
    };
  },
  mounted() {
    this.getCategorioes();
    this.$store.dispatch("bestSelling");
  },
  methods: {
    select_text(val) {
      this.search = val;
    },
    getCategorioes() {
      axios
        .get("/general/all-categories", {
          headers: {
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then((res) => {
          this.allCategories = res.data.data;
        });
    },
    // add to favourite
    doLike(id) {
      this.$store.dispatch("addFavourite", { id });
    },
  },
  computed: {
    filteredItems() {
      if (this.search !== "all") {
        return this.$store.state.allBestSelling.filter((item) => {
          const filterText = item.category_name.match(this.search);
          return filterText;
        });
      } else {
        return this.$store.state.allBestSelling;
      }
    },
  },
  components: {
    Loader,
    NotFound,
  },
};
</script>
