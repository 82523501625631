<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div class="addresses" v-else>
      <h3>{{ mainText.account.addresses.title }}</h3>
      <div class="shipping-billing">
        <NotFound v-if="addresses == ''"></NotFound>
        <div class="row" v-else>
          <div class="col-md-6" v-for="address in addresses" :key="address.id">
            <div class="shipping">
              <h4>{{ address.address_name }}</h4>
              <p>{{ address.street_one }}, {{ address.street_two }}</p>
              <div class="bottom-links">
                <button class="btn delete" @click="deleteAddress(address.id)">
                  {{ mainText.delete }}
                </button>
                <router-link
                  :to="'/edit-address/' + address.id"
                  class="btn edit"
                  >{{ mainText.edit }}<i class="fas fa-long-arrow-alt-right"></i
                ></router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="dash-btn">
              <router-link to="/add-address" class="main-btn btn">{{
                mainText.add
              }}</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div class="all-alert" v-if="alert.alertShow">
        <div class="alert-section">
          <span>
            <i class="fas fa-exclamation-triangle"></i>
          </span>
          <h3>{{ mainText.account.addresses.deleteAddrss }}</h3>
          <ul>
            <li>
              <button type="button" class="delete" @click="deleteAlert">
                {{ mainText.delete }}
              </button>
            </li>
            <li>
              <button
                type="button"
                class="back"
                @click="alert.alertShow = false"
              >
                {{ mainText.cancel }}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from "axios";
import Loader from "../../Loading/Loading";
import textAr from "../../lang/ar.json";
import textEn from "../../lang/en.json";
import NotFound from "../../Loading/not-found";
export default {
  data() {
    return {
      addresses: "",
      daleteId: "",
      loading: false,
      alert: {
        alertShow: false,
      },
      lang: localStorage.getItem("shop_lang"),
      mainText: "",
    };
  },
  methods: {
    getAddresses() {
      this.loading = true;
      axios
        .get("/addresses", {
          headers: {
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            "cache-control": "no-cache",
            Accept: "application/json",
            lang: "en",
          },
        })
        .then((res) => {
          this.loading = false;
          this.addresses = res.data.data;
          console.log(res.data.data);
        });
    },
    // delete address
    deleteAddress(id) {
      this.alert.alertShow = true;
      this.daleteId = id;
    },
    deleteAlert() {
      this.alert.alertShow = false;
      this.loading = true;
      axios
        .delete("/addresses/" + this.daleteId, {
          headers: {
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            "cache-control": "no-cache",
            Accept: "application/json",
            lang: "en",
          },
        })
        .then(() => {
          this.getAddresses();
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getAddresses();
    if (this.lang == "en") {
      this.mainText = textEn;
    } else {
      this.mainText = textAr;
    }
  },
  components: {
    Loader,
    NotFound,
  },
};
</script>
