import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // START:: LOADING KYES
    homeLoading: false,
    mainSliderLoading: false,
    productLoader: false,
    cardsLoading: false,
    partenrsLoading: false,
    cartloading: false,
    // END:: LOADING KYES

    // START:: FAVE AND CART
    favouriteList: null,
    cartList: null,
    cartCountProduct: null,
    // END:: FAVE AND CART
    profileData: null,
    homeData: {
      advertisements: null,
      best_selling: null,
      lowest_price: null,
      categories_no_image: null,
      categories_with_image: null,
      galleries: null,
      latest_news: null,
      our_client_say: null,
      partners: null,
      recommended_products: null,
      top_rated: null,
      large_banners: null,
    },
    AllRecommendedProducts: null,
    allBestOffers: null,
    allBestSelling: null,
    allAuctions: null,
    token: localStorage.getItem("shop-token"),
  },

  mutations: {
    getHomeData(state, payload) {
      // state.homeData.advertisements = payload.advertisements;
      // state.homeData.best_selling = payload.best_selling;
      state.homeData.galleries = payload.galleries;
      state.homeData.categories_with_image = payload.categories_with_image;
      state.homeData.recommended_products = payload.recommended_products;
      state.homeData.lowest_price = payload.lowest_price;
      // state.homeData.categories_no_image = payload.categories_no_image;
      // state.homeData.latest_news = payload.latest_news;
      // state.homeData.our_client_say = payload.our_client_say;
      // state.homeData.partners = payload.partners;
      // state.homeData.top_rated = payload.top_rated;
    },
    recommendedProducts(state, payload) {
      state.AllRecommendedProducts = payload.recommendedProduct;
    },
    bestOffers(state, payload) {
      state.allBestOffers = payload.bestOffer;
    },
    bestSelling(state, payload) {
      state.allBestSelling = payload.bestSele;
    },
    auctions(state, payload) {
      state.allAuctions = payload.auction;
    },

    getFavourite(state, payload) {
      state.favouriteList = payload.faveList;
    },
    getProfileDetails(state, payload) {
      state.profileData = payload.profileDetails;
    },
    getCart(state, payload) {
      state.cartList = payload.theCart;
      state.cartCountProduct = payload.cartCount;
    },

    addFavourite(_, payload) { },
    addToCart(_, payload) { },
  },

  actions: {
    getCart(context, _) {
      context.state.cartloading = true;
      axios
        .get("/carts", {
          headers: {
            Authorization: "bearer " + localStorage.getItem("shop-token"),
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then((res) => {
          context.commit("getCart", {
            theCart: res.data.data,
            cartCount: res.data,
          });
          context.state.cartloading = false;
        });
    },

    getProfileDetails(context, payload) {
      context.state.productLoader = true;
      axios
        .get(`/general/product-details/${payload.product_id}`, {
          headers: {
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then((res) => {
          context.state.productLoader = false;
          context.commit("getProfileDetails", {
            profileDetails: res.data.data,
          });
          context.dispatch("getCart");
        });
    },

    getFavourite(context, _) {
      axios
        .get("/myFavourites", {
          headers: {
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then((res) => {
          context.commit("getFavourite", {
            faveList: res.data.data,
          });
        });
    },

    addFavourite(context, payload) {
      const data = new FormData();
      data.append("product_id", payload.id);
      axios
        .post("/favourite", data, {
          headers: {
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            lang: localStorage.getItem("shop_lang"),
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
          },
        })
        .then(() => {
          new Audio(require("../assets/audio/notification.mp3")).play();
          context.dispatch("getHomeData");
          context.dispatch("recommendedProducts");
          context.dispatch("bestOffers");
          context.dispatch("bestSelling");
          context.dispatch("getProfileDetails", { product_id: payload.id });
          context.dispatch("getFavourite");
          // context.dispatch("getProfileDetails")
        });
    },

    getHomeData(context) {
      context.state.homeLoading = true;
      context.state.mainSliderLoading = true;
      context.state.cardsLoading = true;
      context.state.partenrsLoading = true;
      axios
        .get("/general/home", {
          headers: {
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then((res) => {
          context.state.homeLoading = false;
          context.state.mainSliderLoading = false;
          context.state.cardsLoading = false;
          context.state.partenrsLoading = false;
          // this.allData = res.data.data[0].best_selling;
          context.commit("getHomeData", {
            galleries: res.data.data[0].galleries,
            categories_with_image: res.data.data[0].categories_with_image,
            recommended_products: res.data.data[0].recommended_products,
            lowest_price: res.data.data[0].lowest_price,
            // advertisements: res.data.data[0].advertisements,
            // best_selling: res.data.data[0].best_selling,
            // categories_no_image: res.data.data[0].categories_no_image,
            // latest_news: res.data.data[0].latest_news,
            // our_client_say: res.data.data[0].our_client_say,
            // partners: res.data.data[0].partners,
            // top_rated: res.data.data[0].top_rated,
            // large_banners: res.data.data[0].large_banners,
          });
          
        });
    },

    recommendedProducts(context) {
      context.state.cardsLoading = true;
      axios
        .get("/general/AllRecommendedProducts", {
          headers: {
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then((res) => {
          context.state.cardsLoading = false;
          context.commit("recommendedProducts", {
            recommendedProduct: res.data.data,
          });
        })
        .finally(() => (context.state.cardsLoading = false));
    },

    bestOffers(context) {
      context.state.cardsLoading = true;
      axios
        .get("/general/all-offers", {
          headers: {
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then((res) => {
          context.state.cardsLoading = false;
          context.commit("bestOffers", {
            bestOffer: res.data.data,
          });
        })
        .finally(() => (context.state.cardsLoading = false));
    },

    bestSelling(context) {
      context.state.cardsLoading = true;
      axios
        .get("/general/all-best-selling", {
          headers: {
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then((res) => {
          context.state.cardsLoading = false;
          context.commit("bestSelling", {
            bestSele: res.data.data,
          });
        })
        .finally(() => (context.state.cardsLoading = false));
    },
    auctions(context) {
      context.state.cardsLoading = true;
      axios
        .get("/general/auctions", {
          headers: {
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then((res) => {
          context.state.cardsLoading = false;
          context.commit("auctions", {
            auction: res.data.data,
          });
        })
        .finally(() => (context.state.cardsLoading = false));
    },
  },
  modules: {},
});
