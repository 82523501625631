<template>
  <div>
    <!--start signin-->
    <div class="signin" v-if="mainText">
      <div class="container">
        <div class="row justify-content-center align-self-center">
          <div class="col-md-6">
            <div class="sign-form">
              <div class="logo">
                <router-link to="/" tag="a">
                  <img class="img-responsive" src="@/assets/images/logo.png" />
                </router-link>
                <p>{{ mainText.login.title }}</p>
              </div>
              <form>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="mainText.login.form.phone"
                    v-model="user.email"
                    v-on:keydown.enter.prevent="login"
                  />
                   <div
                    class="errorText email fadeIn"
                    v-if="error.errorEmail"
                  ></div>
                </div>
                <div class="form-group">
                  <input
                    type="password"
                    class="form-control"
                    :placeholder="mainText.login.form.password"
                    v-model="user.password"
                    v-on:keydown.enter.prevent="login"
                  />
                  <!-- erro password -->
                  <div
                    class="errorText password fadeIn"
                    v-if="error.errorPassword"
                  ></div>
                </div>
                <div class="forget-pass">
                  <router-link to="/enter-phone">{{
                    mainText.login.form.forgetPass
                  }}</router-link>
                </div>
                <button
                  type="button"
                  ref="login"
                  @click="login"
                  class="dark-button login-button button btn main-btn"
                >
                  <i class="fas fa-spinner"></i>
                </button>
              </form>
              <div class="bottom-sign">
                <div class="have-account">
                  <p>
                    {{ mainText.login.form.dontHave }}
                    <router-link to="/sign-up">
                      {{ mainText.login.form.signUp }}</router-link
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="shape">
        <img src="@/assets/images/shapes/shape2.png" />
      </div>
    </div>
    <!--end signin-->
    <!-- error alert -->
    <transition name="slide-fade">
      <div class="error-alert" v-if="error.static.error">
        <span @click="error.static.error = false">
          <i class="fas fa-times"></i>
        </span>
        <p>{{ error.static.text }}</p>
      </div>
    </transition>
    <!-- success alert -->
    <transition name="slide-fade">
      <div class="success-alert" v-if="success">
        <span @click="success = false"> <i class="fas fa-times"></i> </span>
        <p>{{ mainText.login.form.successLogin }}</p>
      </div>
    </transition>
  </div>
</template>
<script>
import axios from "axios";
import textAr from "../../lang/ar.json";
import textEn from "../../lang/en.json";
import firebase from "firebase";
export default {
  name: "user-login",
  data() {
    return {
      visibility: "password",
      user: {
        email: "",
        password: "",
      },
      error: {
        errorEmail: false,
        errorPassword: false,
        static: {
          error: false,
          text: "",
        },
      },
      success: false,
      lang: localStorage.getItem("shop_lang"),
      mainText: "",
    };
  },
  methods: {
    loginWithFacebook() {
      const facebookProvider = new firebase.auth.FacebookAuthProvider();
      firebase
        .auth()
        .signInWithPopup(facebookProvider)
        .then(() => {
          this.sendFacebookLogin();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    loginWithGoogle() {
      const provider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(() => {
          this.sendGoogleLogin();
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    sendFacebookLogin() {
      const data = new FormData();
      data.append("full_name", firebase.auth().currentUser.displayName);
      data.append("user_name", firebase.auth().currentUser.displayName);
      data.append("email", firebase.auth().currentUser.providerData[0].email);
      data.append("provider_type", "facebook");
      data.append("access_token", firebase.auth().currentUser.uid);
      data.append("device_token", "deviceTokenGoogleFacebook");
      data.append("device_type", "android");
      axios
        .post("/auth/social", data, {
          headers: {
            "cache-control": "no-cache",
            "Content-type": `multipart/form-data;`,
            Accept: "application/json",
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then((res) => {
          localStorage.setItem("shop-token", res.data.data.token);

          this.success = true;
          setTimeout(() => {
            this.success = false;
            this.$router.push({ path: "/account-details" });
          }, 1500);
        });
    },
    sendGoogleLogin() {
      const data = new FormData();
      data.append("full_name", firebase.auth().currentUser.displayName);
      data.append("user_name", firebase.auth().currentUser.displayName);
      data.append("email", firebase.auth().currentUser.email);
      data.append("provider_type", "google");
      data.append("access_token", firebase.auth().currentUser.uid);
      data.append("device_type", "android");
      data.append("device_token", "deviceTokenGoogleWeb");
      axios
        .post("/auth/social", data, {
          headers: {
            "cache-control": "no-cache",
            "Content-type": `multipart/form-data;`,
            Accept: "application/json",
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then((res) => {
          localStorage.setItem("shop-token", res.data.data.token);
          this.success = true;
          console.log(firebase.auth());
          setTimeout(() => {
            this.success = false;
            this.$router.push({ path: "/account-details" });
          }, 1500);
        })
        .catch((error) => {
          this.error.static.text = error.response.data.msg;
        });
    },
    // login
    login() {
      const submitButton = this.$refs["login"];
      submitButton.classList.add("click");
      this.error.errorEmail = false;
      this.error.errorPassword = false;
      this.error.static.error = false;
      if (this.user.email == "") {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.error.errorEmail = true;
          this.error.errorPassword = false;
          this.error.static.error = false;
        }, 1000);
      } else if (this.user.password == "") {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.error.errorEmail = false;
          this.error.errorPassword = true;
          this.error.static.error = false;
        }, 1000);
      } else {
        const data = new FormData();
        data.append("email", this.user.email);
        data.append("password", this.user.password);
        data.append("device_token", "dddd");
        data.append("device_type", "web");
        axios
          .post("/auth/login", data, {
            headers: {
              "cache-control": "no-cache",
              "Content-type": `multipart/form-data;`,
              Accept: "application/json",
              lang: localStorage.getItem("shop_lang"),
            },
          })
          .then((res) => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.error.static.error = false;
              this.success = true;
              localStorage.setItem("shop-token", res.data.data.token);
            }, 1000);
            setTimeout(() => {
              this.$router.push({ path: "/" });
            }, 2000);
          })
          .catch((error) => {
            this.error.static.error = true;
            submitButton.classList.remove("click");
            this.waiting = false;
            this.error.errorEmail = false;
            this.error.errorPassword = false;
            this.error.static.text = error.response.data.msg;
          })
          .finally(() => (this.waiting = false));
      }
    },
  },
  mounted() {
    var userType = localStorage.getItem("shop-token");
    if (userType) {
      this.$router.push({ path: "/" });
    }
    if (this.lang == "en") {
      this.mainText = textEn;
    } else {
      this.mainText = textAr;
    }
  },
  components: {},
  created() {},
};
</script>
