<template>
  <div>
    <!--start page-banner-->

    <section class="page-banner">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <!--breadcrumbs-->
            <div class="breadcrumbs">
              <h3 v-if="lang == 'en'">Order Traking</h3>
              <h3 v-if="lang == 'ar'">تتبع الطلب</h3>
              <ul class="list-unstyled">
                <li v-if="lang == 'en'">
                  <router-link to="">Home </router-link>>
                </li>
                <li v-if="lang == 'ar'">
                  <router-link to="">الرئيسية </router-link>>
                </li>
                <li v-if="lang == 'en'">Order Traking</li>
                <li v-if="lang == 'ar'">تتبع الطلب</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--end page-banner-->

    <!--start order-tracking-->

    <section class="order-tracking">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div class="my-form">
              <form>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    v-model="email"
                    v-if="lang == 'en'"
                    placeholder="Email Addrees"
                  />
                  <input
                    type="text"
                    class="form-control"
                    v-model="email"
                    v-if="lang == 'ar'"
                    placeholder="البريد الالكتروني"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    v-model="order"
                    v-if="lang == 'en'"
                    placeholder="order Code"
                  />
                  <input
                    type="text"
                    class="form-control"
                    v-model="order"
                    v-if="lang == 'ar'"
                    placeholder="رقم الطلب"
                  />
                </div>
                <div class="dash-btn">
                  <!-- <button
                    type="button"
                    @click="orderTrack"
                    ref="track"
                    class="btn main-btn"
                  >
                    Send
                  </button> -->
                  <button
                    type="button"
                    ref="track"
                    @click="orderTrack"
                    class="dark-button rate-button button btn main-btn"
                  >
                    <i class="fas fa-spinner"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!-- error alert -->
        <transition name="slide-fade">
          <div class="error-alert" v-if="error.static.error">
            <span @click="error.static.error = false">
              <i class="fas fa-times"></i>
            </span>
            <p>{{ error.static.text }}</p>
          </div>
        </transition>
        <!-- success alert -->
        <transition name="slide-fade">
          <div class="success-alert" v-if="success">
            <span @click="success = false"> <i class="fas fa-times"></i> </span>
            <p v-if="lang == 'en'">Check Your Email To Track Order</p>
            <p v-if="lang == 'ar'">راجع بريدك الالكتروني لتتبع الطلب</p>
          </div>
        </transition>
        <!--end signin-->
      </div>
    </section>

    <!--end order-tracking-->
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      email: "",
      order: "",
      error: {
        static: {
          error: false,
          text: "",
        },
      },
      success: false,
      lang: localStorage.getItem("shop_lang"),
    };
  },
  methods: {
    orderTrack() {
      const submitButton = this.$refs["track"];
      submitButton.classList.add("click");
      this.error.static.error = false;
      if (this.email == "") {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.error.static.error = true;
          if (this.lang == "en") {
            this.error.static.text = "Please Enter Email Address";
          } else {
            this.error.static.text = "يرجى ادخال البريد الالكتروني";
          }
          this.success = false;
        }, 1000);
      } else if (this.order == "") {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.error.static.error = true;
          if (this.lang == "en") {
            this.error.static.text = "Please Enter Order Id";
          } else {
            this.error.static.text = "يرجى ادخال رقم الطلب";
          }
          this.success = false;
        }, 1000);
      } else {
        const data = new FormData();
        data.append("email", this.email);
        data.append("order_id", this.order);
        axios
          .post("/general/order-track", data, {
            headers: {
              "cache-control": "no-cache",
              "Content-type": `multipart/form-data;`,
              Accept: "application/json",
              lang: localStorage.getItem("shop_lang"),
            },
          })
          .then(() => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.error.static.error = false;
              this.success = true;
            }, 1000);
          })
          .catch((error) => {
            this.error.static.error = true;
            submitButton.classList.remove("click");
            this.waiting = false;
            this.error.static.text = error.response.data.msg;
          })
          .finally(() => (this.waiting = false));
      }
    },
  },
};
</script>
