<template>
  <div>
    <loader v-if="laoding"></loader>
    <div class="orders" v-else>
      <h4>Order #{{ allData.id }}</h4>
      <div class="ord-details">
        <div class="ord-status pending" v-if="allData.status == 'pending'">
          <h4>
            {{ allData.status }}
          </h4>
        </div>
        <div
          class="ord-status processing"
          v-if="allData.status == 'processing'"
        >
          <h4>
            {{ allData.status }}
          </h4>
        </div>
        <div class="ord-status deliverd" v-if="allData.status == 'deliverd'">
          <h4>
            <span class="icon"><i class="fas fa-check-circle"></i></span
            >{{ allData.status }}
          </h4>
        </div>
        <div class="ord-status cancelled" v-if="allData.status == 'cancelled'">
          <h4>
            {{ allData.status }}
          </h4>
        </div>
        <div class="ord-status in_way" v-if="allData.status == 'in_way'">
          <h4>
            {{ allData.status }}
          </h4>
        </div>
        <div class="order-time">
          <span>{{ allData.created_at }}</span>
        </div>
      </div>
      <div class="order-product">
        <div
          class="one-product"
          v-for="item in allData.order_items"
          :key="item.id"
        >
          <!-- product image-->
          <div class="product-img">
            <img :src="item.main_image" class="owl-lazy" />
          </div>
          <!-- product description -->
          <div class="product-exp">
            <!-- product title -->
            <div class="product-title">
              <h5>{{ item.name }}</h5>
              <span>{{ item.brand_name }}</span>
            </div>
            <!-- product add , show and like -->
            <div class="price-fav">
              <div class="price">
                <span class="new-price"
                  >{{ item.price }} <span class="currency"></span
                ></span>
                <span class="old-price" v-if="item.offer_price != null"
                  >{{ item.offer_price }} <span class="currency"></span
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="view-ord-totals">
        <div class="view-tot">
          <span>{{ mainText.home.cart.billDetails.subtotal }}</span>
          <h4>{{ allData.sub_total_price }} <span class="currency"></span></h4>
        </div>
        <div class="view-tot">
          <span>{{ mainText.home.cart.billDetails.shipping }}</span>
          <h4>{{ allData.shipping_price }} <span class="currency"></span></h4>
        </div>
        <div class="view-tot">
          <h3>{{ mainText.home.cart.billDetails.total }}</h3>
          <h4>{{ allData.final_price }} <span class="currency"></span></h4>
        </div>
      </div>
      <div class="shipping-payment">
        <div class="row">
          <div class="col-md-6" v-if="allData.address != null">
            <div class="ship-ord">
              <h3>{{ allData.address.address_name }}</h3>
              <p>
                {{ allData.address.street_one }},
                {{ allData.address.street_two }}
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="pay-ord">
              <h3>Payment</h3>
              <div class="media">
                <div class="media-body">
                  <div class="card-name">
                    <span>{{ allData.payment_method }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import textAr from "../../lang/ar.json";
import textEn from "../../lang/en.json";
import loader from "../../Loading/Loading";
export default {
  data() {
    return {
      allData: "",
      loading: false,
      id: this.$route.params.id,
      lang: localStorage.getItem("shop_lang"),
      mainText: "",
    };
  },
  methods: {
    getAll() {
      this.loading = true;
      axios
        .get("/order-details/" + this.id, {
          headers: {
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then((res) => {
          this.loading = false;
          this.allData = res.data.data;
        });
    },
  },
  components: {
    loader,
  },
  mounted() {
    this.getAll();
    if (this.lang == "en") {
      this.mainText = textEn;
    } else {
      this.mainText = textAr;
    }
  },
};
</script>
