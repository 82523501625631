<template>
  <div class="client-opinion" v-if="mainText">
    <div class="container">
      <Loader v-if="loading" class="fadeIn"></Loader>
      <div class="newsletter_section" v-else>
        <h3>{{ mainText.home.titles.newsletter }}!</h3>
        <form>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group d-flex">
                <input
                  type="email"
                  :placeholder="mainText.account.editAccount.form.email"
                  v-model="email"
                  class="form-control"
                />
                <button
                  @click="addEmail"
                  type="button"
                  class="dark-button button btn main-btn"
                >
                  <span v-if="lang == 'ar'">إشترك</span>
                  <span v-if="lang == 'en'">Subscribe</span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- error alert -->
    <transition name="slide-fade">
      <div class="error-alert" v-if="error.static.error">
        <span @click="error.static.error = false">
          <i class="fas fa-times"></i>
        </span>
        <p>{{ error.static.text }}</p>
      </div>
    </transition>
    <!-- success alert -->
    <transition name="slide-fade">
      <div class="success-alert" v-if="success">
        <span @click="success = false"> <i class="fas fa-times"></i> </span>
        <p>{{ mainText.home.titles.newsletter_done }}</p>
      </div>
    </transition>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel2";
import axios from "axios";
import Loader from "./../Loading/client";
import textAr from "../lang/ar.json";
import textEn from "../lang/en.json";
import NotFound from "./../Loading/not-found";
export default {
  data() {
    return {
      loading: false,
      allData: "",
      lang: localStorage.getItem("shop_lang"),
      mainText: "",
      email: null,
      error: {
        errorEmail: false,
        errorPassword: false,
        static: {
          error: false,
          text: "",
        },
      },
      success: false,
    };
  },
  mounted() {
    if (this.lang == "en") {
      this.mainText = textEn;
    } else {
      this.mainText = textAr;
    }
  },
  methods: {
    addEmail() {
      this.waiting = true;
      this.error.static.error = false;

      if (this.email == "") {
        setTimeout(() => {
          this.error.static.error = true;
          this.error.static.text = "يرجي إدخال البريد الإلكتروني";
          this.waiting = false;
          this.success = false;
        }, 1000);
      } else {
        this.error.static.error = false;
        const data = new FormData();
        // ===== Name
        data.append("email", this.email);
        axios
          .post("/general/newsletter", data, {
            headers: {
              Authorization: "bearer" + localStorage.getItem("shop-token"),
              "cache-control": "no-cache",
              Accept: "application/json",
            },
          })
          .then(() => {
            this.waiting = false;
            this.error.static.error = false;
            this.success = true;
            setTimeout(() => {
              this.success = false;
            }, 5000);
          })
          .catch((error) => {
            this.error.static.error = true;
            setTimeout(() => {
              this.error.static.error = false;
            }, 5000);
            this.error.static.text = error.response.data.msg;
          })
          .finally(() => (this.waiting = false));
      }
    },
  },
  components: {
    Loader,
    carousel,
    NotFound,
  },
};
</script>
