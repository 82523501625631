<template>
  <!--strat our-partners-->

  <div class="our-partners">
    <Loader v-if="$store.state.partenrsLoading" class="fadeIn"></Loader>
    <div class="container" v-else>
      <NotFound v-if="$store.state.homeData.partners == ''"></NotFound>
      <div class="row" v-else>
        <div class="col-sm-12">
          <carousel
            :items="5"
            :autoplay="true"
            :loop="true"
            :dots="false"
            class="owl-partners fadeIn"
            :responsive="{
              0: {
                items: 1,
                nav: false,
              },
              600: {
                items: 2,
                nav: false,
              },
              800: {
                items: 5,
                nav: false,
              },
            }"
          >
            <div
              class="item"
              v-for="item in this.$store.state.homeData.partners"
              :key="item.id"
            >
              <div class="image">
                <router-link to="/"><img :src="item.image" /></router-link>
              </div>
            </div>
          </carousel>
        </div>
      </div>
    </div>
  </div>

  <!--end our-partners-->
</template>

<script>
import carousel from "vue-owl-carousel";
// import axios from "axios";
import Loader from "./../Loading/partenrs";
import NotFound from "./../Loading/not-found";
export default {
  data() {
    return {
      loading: true,
      allData: "",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      // if (this.$store.state.homeData.partners != "") {
      //   this.loading = false;
      // }
    },
  },
  components: {
    Loader,
    carousel,
    NotFound,
  },
};
</script>
