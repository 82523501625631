<template>
  <!--start top-header-->
  <section
    class="top-header"
    :class="{ show_shadow: $route.name !== 'main-home' }"
  >
    <div class="container">
      <div class="row">
        <!-- START:: LOGO -->
        <div class="col-6 col-lg-2">
          <LogoSite />
        </div>

        <!-- START:: MAIN MENU -->
        <!-- :class="{'col-lg-7': !token, 'col-lg-6': token}" -->
        <div class="col-6 col-lg-8">
          <MainMenu />
        </div>

        <!-- START::   -->
        <!-- :class="{'col-lg-2': !token, 'col-lg-3': token}" -->
        <div class="col-6 col-lg-2">
          <div class="right-head">
            <!---sign up--->
            <div class="sign-btn" v-if="!token">
              <router-link to="/sign-up" v-if="lang === 'en'"
                >Sign Up</router-link
              >
              <router-link to="/sign-up" v-else>تسجيل دخول</router-link>
            </div>

            <!-- <div class="sign-btn" v-if="token">
              <button type="button" @click="Logout()" v-if="lang === 'en'">
                Logout
              </button>
              <button type="button" @click="Logout()" v-if="lang === 'ar'">
                تسجيل الخروج
              </button>
            </div> -->
            <!-- START:: SEARCH COMPONNET -->
            <Search></Search>
            <!-- START:: CART COMPONNET -->
            <Cart v-if="token"></Cart>
            <!-- START:: FAV COMPONNET -->
            <Favourite v-if="token"></Favourite>
            <!-- link of profile on header -->
            <div v-if="token" class="profile-icon">
              <router-link to="/my-account">
                <div class="profile-icons"></div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--end top-header-->
</template>

<script>
import LogoSite from "./logoSite.vue";
import MainMenu from "./MainMenu";
import Cart from "./Cart";
import Search from "./Search";
import Favourite from "./Favourite";
import axios from "axios";
export default {
  data() {
    return {
      show: false,
      search: {
        text: "",
      },
      // lang
      token: localStorage.getItem("shop-token"),
      lang: localStorage.getItem("shop_lang"),
    };
  },
  components: {
    MainMenu,
    Cart,
    Favourite,
    LogoSite,
    Search,
  },
  methods: {
    // search method
    searchMethod() {
      if (this.search.text !== "") {
        this.$router.push({
          name: "search",
          params: { text: this.search.text },
        });
      }
    },
    hideSearch() {
      this.show = false;
    },
    showSearch() {
      this.show = true;
    },
    Logout() {
      axios
        .post("/auth/logout", [], {
          headers: {
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            "cache-control": "no-cache",
            Accept: "application/json",
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then(() => {
          localStorage.removeItem("shop-token");
          setTimeout(() => {
            this.$router.push({ path: "/login" });
          }, 700);
        });
    },
  },
  mounted() {},
};
</script>
