<template>
  <!--start banner-->

  <section class="categories_section options_section mt-5">
    <OptionsLoader v-if="loading" class="fadeIn"></OptionsLoader>
    <div v-else class="container">
      <div class="all-categories row justify-content-center">
        <!-- <div class="col-md-4">
          <router-link to="/all-auctions">
            <div class="single-category">
              <h3 class="title-category">
                <span v-if="lang == 'en'"><span>Au</span>ctions</span>
                <span v-else><span>مز</span>ادات</span>
              </h3>
            </div>
          </router-link>
        </div> -->
        <div class="col-md-4">
          <router-link to="/best-offers">
            <div class="single-category">
              <h3 class="title-category">
                <span v-if="lang == 'en'"><span>30%</span> Off Deals</span>
                <span v-else><span>الـ</span>عروض</span>
              </h3>
            </div>
          </router-link>
        </div>
        <div class="col-md-4">
          <router-link to="/">
            <div class="single-category">
              <h3 class="title-category">
                <span v-if="lang == 'en'"><span>Ho</span>ney Jar's</span>
                <span v-else><span>Ho</span>ney Jar's</span>
              </h3>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </section>

  <!--end banner-->
</template>
<script>
import OptionsLoader from "./../Loading/OptionsLoader";
import textAr from "../lang/ar.json";
import textEn from "../lang/en.json";
export default {
  data() {
    return {
      loading: false,
      allData: "",
      lang: localStorage.getItem("shop_lang"),
      mainText: "",
    };
  },
  mounted() {
    if (this.lang == "en") {
      this.mainText = textEn;
    } else {
      this.mainText = textAr;
    }
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
  methods: {},
  components: {
    OptionsLoader,
  },
};
</script>
