<template>
  <div>
    <!--start page-banner-->

    <div class="page-banner">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <!--breadcrumbs-->
            <div class="breadcrumbs">
              <h3>{{ mainText.search.title }}</h3>
              <ul class="list-unstyled">
                <li>
                  <router-link to="/">{{ mainText.home.title }} </router-link>>
                </li>
                <li>{{ mainText.search.title }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--end page-banner-->

    <!--start product-category-->
    <loading v-if="loading"></loading>
    <section class="product-category" v-else>
      <div class="container">
        <div class="products">
          <div class="fadeIn" v-if="allData == ''">
            <NotFound></NotFound>
          </div>
          <div class="row" v-else>
            <!-- product card -->
            <div
              class="col-6 col-lg-3 col-md-6 fadeIn"
              v-for="item in allData"
              :key="item.id"
            >
              <div class="one-product">
                <!-- product image-->
                <router-link :to="'/product/' + item.id">
                  <div class="product-img">
                    <img v-lazy="item.main_image" />
                    <img class="hover_image" v-lazy="item.hover_image" />
                  </div>
                </router-link>
                <!-- product description -->
                <div class="product-exp">
                  <!-- product title -->
                  <div class="product-title">
                    <h5>{{ item.name }}</h5>
                    <span>{{ item.brand_name }}</span>
                  </div>
                  <!-- product add , show and like -->
                  <div class="price-fav">
                    <div class="price">
                      <span class="new-price" v-if="item.is_offered != 0"
                        ><span class="currency"></span>
                        {{ item.offer_price }}</span
                      >
                      <span class="new-price" v-if="item.is_offered == 0"
                        ><span class="currency"></span> {{ item.price }}</span
                      >
                      <span class="old-price" v-if="item.is_offered != 0"
                        ><span class="currency"></span> {{ item.price }}</span
                      >
                    </div>
                  </div>
                </div>
                <span class="pro-stat" v-if="item.is_offered != 0">
                  <span v-if="lang == 'en'">Sale !</span>
                  <span v-else>تخفيض !</span>
                </span>
              </div>
              <div class="text-center mb-5">
                <router-link
                  :to="'/product/' + item.id"
                  class="dark-button button btn main-btn home-btn"
                >
                  <span v-if="lang == 'en'">Select Option</span>
                  <span v-if="lang == 'ar'">حدد خيار</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        @click="hideFilterMenu()"
        class="overlay"
        :class="{ disblock: show }"
      ></div>
    </section>

    <!--end product-category-->
  </div>
</template>

<script>
import axios from "axios";
import loading from "../Loading/cards";
import textAr from "../lang/ar.json";
import textEn from "../lang/en.json";
import NotFound from "./../Loading/not-found";
export default {
  data() {
    return {
      loading: false,
      text: this.$route.params.text,
      allData: "",
      pageTitle: "",
      lang: localStorage.getItem("shop_lang"),
      mainText: "",
    };
  },
  methods: {
    // get all
    getAll() {
      // loading
      this.loading = true;
      const data = new FormData();
      data.append("name", this.text);
      axios
        .post("/general/search", data, {
          headers: {
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            "cache-control": "no-cache",
            "Content-type": `multipart/form-data;`,
            Accept: "application/json",
            lang: "en",
          },
        })
        .then((res) => {
          this.loading = false;
          this.allData = res.data.data;
        })
        .finally(() => (this.loading = false));
    },
  },
  mounted() {
    this.getAll();
    if (this.lang == "en") {
      this.mainText = textEn;
    } else {
      this.mainText = textAr;
    }
  },
  components: {
    loading,
    NotFound,
  },
};
</script>
