<template>
  <div>
    <!--start page-banner-->

    <section class="page-banner">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <!--breadcrumbs-->
            <div class="breadcrumbs">
              <h3>{{ mainText.payment.title }}</h3>
              <ul class="list-unstyled">
                <li>
                  <router-link to="/"
                    >{{ mainText.account.breadcrumbs.home }} </router-link
                  >>
                </li>
                <li>{{ mainText.payment.title }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--end page-banner-->
    <loader v-if="loading" class="fadeIn"></loader>
    <div class="checkout fadeIn" v-else>
      <div class="wizard-section">
        <div class="container">
          <div class="row no-gutters">
            <div class="col-lg-12 col-md-12">
              <div class="form-wizard">
                <form class="form-horizontal">
                  <div class="form-wizard-header">
                    <p v-if="lang == 'en'">Payment Information</p>
                    <p v-if="lang == 'ar'">معلومات الدفع</p>
                    <ul class="list-unstyled form-wizard-steps clearfix">
                      <li class="active"><span></span></li>
                      <li :class="[step == 2 ? 'active' : '']">
                        <span></span>
                      </li>
                      <!-- <li><span></span></li> -->
                    </ul>
                  </div>

                  <fieldset v-if="step == 1" class="fadeIn">
                    <!-- <NotFound v-if="addresses !== ''"></NotFound> -->
                    <div class="checkout-addresses">
                      <h3 class="subtitle" v-if="lang == 'en'">
                        please choose your shipping address
                      </h3>
                      <h3 class="subtitle" v-else>يرجي إختيار عنوان التوصيل</h3>
                      <div class="spe-not-found fadeIn" v-if="addresses == ''">
                        <span class="icon">
                          <i class="fas fa-exclamation-circle"></i>
                        </span>
                        <h3 v-if="lang == 'en'">
                          Sorry There Is Address Found Here
                        </h3>
                        <h3 v-if="lang == 'ar'">
                          عفوا لم يتم ايجاد عنوان التوصيل
                        </h3>
                      </div>
                      <div class="row" v-else>
                        <div
                          class="col-md-6"
                          v-for="addres in addresses"
                          :key="addres.id"
                        >
                          <div class="choose-address">
                            <input
                              type="radio"
                              :id="'radio' + addres.id"
                              name="e"
                              @click="
                                selectAddress(addres.id, addres.address_name)
                              "
                            />
                            <label :for="'radio' + addres.id" class="lab-add">
                              <div class="address-card">
                                <h4>{{ addres.address_name }}</h4>
                                <p>
                                  {{ addres.street_one }},
                                  {{ addres.street_two }}
                                </p>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="buttons-form">
                        <router-link
                          to="/add-address"
                          class="btn main-btn"
                          v-if="addresses == ''"
                        >
                          {{ mainText.add }}
                        </router-link>
                        <!-- <button
                          @click.prevent="next()"
                          class="btn main-btn"
                          v-else
                        >
                          {{ mainText.next }}
                        </button> -->
                        <button
                          type="button"
                          v-else
                          ref="next"
                          @click.prevent="next()"
                          class="dark-button forget-button button btn main-btn"
                        >
                          <i class="fas fa-spinner"></i>
                        </button>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset v-if="step == 2" class="fadeIn">
                    <div class="check-last">
                      <div class="top-products">
                        <div
                          class="sin-prod"
                          v-for="item in allData"
                          :key="item.id"
                        >
                          <div class="sin-img">
                            <img :src="item.product_image" />
                          </div>
                          <div class="sin-det">
                            <h5>{{ item.product_name }}</h5>
                            <span class="cat">{{ item.category_name }}</span>
                            <div class="price">
                              <span class="new">{{ item.product_price }}</span>
                              <span class="currency"></span>
                              <strong class="mx-2"
                                >x{{ item.product_quantity }}</strong
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="hint">
                        <h4 v-if="lang == 'ar'">
                          مدة التوصيل من
                          <span>يومين</span>
                          الي
                          <span>5 ايام</span>
                          عمل
                        </h4>

                        <h4 v-else>
                          Delivery time from
                          <span>2</span>
                          to
                          <span>5 days</span>
                        </h4>
                      </div>
                      <div class="middle-details">
                        <div class="mid-flex">
                          <span>{{
                            mainText.home.cart.billDetails.subtotal
                          }}</span>
                          <h4>
                            {{ otherData.sub_total_price }}
                            <span class="currency"></span>
                          </h4>
                        </div>
                        <div class="mid-flex" v-if="otherData.discount != 0">
                          <span>{{
                            mainText.home.cart.billDetails.discount
                          }}</span>
                          <h4>
                            {{ otherData.discount }}
                            <span class="currency"></span>
                          </h4>
                        </div>
                        <div class="mid-flex">
                          <span>{{
                            mainText.home.cart.billDetails.shipping
                          }}</span>
                          <h4 v-if="myAddress">
                            {{ myAddress.country.shipping_price }}

                            <span class="currency"></span>
                          </h4>
                        </div>
                        <div class="mid-flex">
                          <span class="tot">{{
                            mainText.home.cart.billDetails.total
                          }}</span>
                          <h4 v-if="otherData && myAddress">
                            {{
                              otherData.final_price +
                              myAddress.country.shipping_price
                            }}
                            <span class="currency"></span>
                          </h4>
                        </div>
                        <!-- <div class="mid-flex">
                          <span class="tot">{{
                            mainText.home.cart.billDetails.payType
                          }}</span>
                          <h4>
                            {{ mainText.home.cart.billDetails.cash }}
                          </h4>
                        </div> -->
                      </div>

                      <hr class="line" />

                      <div class="choose-add-pay pay_type">
                        <div class="choose-add">
                          <h5 v-if="lang == 'en'">Payment Type</h5>
                          <h5 v-else>طريقة الدفع</h5>
                          <ul class="list-unstyled">
                            <li class="pay_list">
                              <input
                                type="radio"
                                name="pay"
                                id="pay1"
                                v-model="pay_type"
                                value="cash"
                              />
                              <label for="pay1" v-if="lang == 'en'"
                                >Pay Ondelivery</label
                              >
                              <label for="pay1" v-else
                                >الدفع عند الاستلام</label
                              >
                            </li>
                            <li class="pay_list">
                              <input
                                type="radio"
                                name="pay"
                                id="pay2"
                                value="online"
                                v-model="pay_type"
                              />
                              <label for="pay2" v-if="lang == 'en'"
                                >Pay Online</label
                              >
                              <label for="pay2" v-else>الدفع اون لاين</label>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <!-- <div class="choose-add-pay my-5">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="choose-add">
                              <h5 v-if="lang == 'en'">Shipping Address</h5>
                              <h5 v-else>عنوان التوصيل</h5>
                              <div class="address-card">
                                <h4>{{ myAddress.address_name }}</h4>
                                <p>
                                  {{ myAddress.location }},
                                  {{ myAddress.country.name }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> -->
                    </div>
                    <div class="buttons-form">
                      <button @click.prevent="prev()" class="btn main-btn">
                        {{ mainText.prev }}
                      </button>
                      <button
                        type="button"
                        class="dark-button check-button-complate button btn main-btn"
                        @click="paymentMethod()"
                        ref="update"
                        v-if="pay_type == 'cash'"
                      >
                        <i class="fas fa-spinner"></i>
                      </button>
                      <button
                        type="button"
                        class="dark-button check-button-complate button btn main-btn"
                        @click="Stripepayment()"
                        ref="update"
                        v-if="pay_type == 'online'"
                      >
                        <!-- <i class="fas fa-spinner"></i> -->
                      </button>
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <stripe-checkout
      ref="checkoutRef"
      :pk="publishableKey"
      :session-id="sessionId"
      :success-url="stripe_session_data.success_url"
      :cancel-url="stripe_session_data.cancel_url"
      :line-items="stripe_session_data.line_items"
      :mode="stripe_session_data.mode"
      :payment_method_types="stripe_session_data.payment_method_types"
    />
    <!-- success alert -->
    <transition name="slide-fade">
      <div class="success-alert" v-if="success">
        <span @click="success = false"> <i class="fas fa-times"></i> </span>
        <p v-if="lang == 'ar'">
          تم تحديد {{ placeName }} كعنوان لتوصيل الطلبات
        </p>
        <p v-if="lang == 'en'">you Choose {{ placeName }} As Deliver Address</p>
      </div>
      <div class="success-alert" v-if="successDone">
        <span @click="successDone = false"> <i class="fas fa-times"></i> </span>
        <p v-if="lang == 'ar'">تم طلب الأوردر بنجاح</p>
        <p v-if="lang == 'en'">successfully order</p>
      </div>
    </transition>
    <transition name="slide-fade">
      <div class="error-alert" v-if="error.static.error">
        <span @click="error.static.error = false">
          <i class="fas fa-times"></i>
        </span>
        <p>{{ error.static.text }}</p>
      </div>
    </transition>
    <!-- START:: CUSTOM MODAL -->
    <transition name="slide-fade">
      <div class="custom_modal_checkout" v-if="modalIsOpen">
        <div class="content_modal">
          <!-- START:: HEAD -->
          <div class="head_modal">
            <h3>
              {{ mainText.payment.statusTitle }}
            </h3>
          </div>
          <!-- END:: HEAD -->

          <!-- START:: BODY -->
          <div class="body_modal">
            <div class="icon_status">
              <img
                src="@/assets/images/icons/failed.svg"
                v-if="statusPay == 'FAILED'"
              />
              <img src="@/assets/images/icons/succsess.svg" v-else />
            </div>
            <div class="text_status">
              <template v-if="statusPay == 'FAILED'">
                <h4>{{ mainText.payment.failed }}</h4>
                <button
                  type="button"
                  @click="modalIsOpen = false"
                  class="btn main-btn mt-3"
                >
                  {{ mainText.payment.payAgain }}
                </button>
              </template>
              <template v-else>
                <h4>{{ mainText.payment.success }}</h4>
                <p>
                  {{ mainText.payment.wait }}
                  <span v-if="lang == 'ar'">أو</span>
                  <span v-else>Or</span>
                </p>
                <router-link to="/orders" class="btn main-btn">
                  {{ mainText.payment.view }}
                </router-link>
              </template>
            </div>
          </div>
          <!-- END:: BODY -->
        </div>
      </div>
    </transition>
    <!-- END:: CUSTOM MODAL -->
  </div>
</template>

<script>
import axios from "axios";
import textAr from "../lang/ar.json";
import textEn from "../lang/en.json";
import loader from "../Loading/Loading";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
// import NotFound from "../Loading/not-found";
export default {
  data() {
    return {
      loading: false,
      step: 1,
      email: "",
      pwd: "",
      fname: "",
      lname: "",
      mobno: "",
      address: "",
      addresses: "",
      myAddress: "",
      alertShow: false,
      success: false,
      successDone: false,
      placeName: "",
      placeId: "",
      allData: [],
      otherData: "",
      copounVal: "",
      transaction_id: this.$route.query.transaction_id,
      statusPay: this.$route.query.status,
      modalIsOpen: false,
      pay_type: null,
      error: {
        static: {
          error: false,
          text: "",
        },
      },
      lang: localStorage.getItem("shop_lang"),
      mainText: "",

      //stripe
      stripe_session_data: {
        success_url: "",
        cancel_url: "",
        payment_method_types: ["card"],
        line_items: [
          {
            price: "2000",
            quantity: 1,
          },
        ],
        mode: "payment",
      },
      publishableKey:
        "pk_test_51KgKziFtXIxP3TL5iADFjl8y0ZoU3OCyACPWkJ0IIQnitM3yHAEbv1b14WEOHMVfkIP9udKyv7y4n6APYr9LnXM1001993r3bm",

      sessionId: null,
      waiting_payment: false,
      paidStatus: false,
      seeAllPayment: false,
      unique_id: null,
    };
  },
  methods: {
    getCart() {
      this.loading = true;
      axios
        .get("/carts", {
          headers: {
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then((res) => {
          this.allData = res.data.data;
          this.otherData = res.data;
          this.loading = false;
        });
    },
    getAddresses() {
      axios
        .get("/addresses", {
          headers: {
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then((res) => {
          this.addresses = res.data.data;
        });
    },
    // select address
    selectAddress(id, name) {
      this.laoding = true;
      const data = new FormData();
      data.append("address_id", id);
      axios
        .post("/select/address", data, {
          headers: {
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            "cache-control": "no-cache",
            "Content-type": `multipart/form-data;`,
            Accept: "application/json",
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then(() => {
          this.laoding = false;
          this.placeName = name;
          this.placeId = id;
          this.success = true;
          this.error.static.error = false;
          this.get_selected_address();
          localStorage.setItem("knooz-address-selected", this.placeId);
          setTimeout(() => {
            this.success = false;
          }, 1000);
        });
    },

    //get selected address
    get_selected_address() {
      axios
        .get("/addresses/" + this.placeId, {
          headers: {
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            "cache-control": "no-cache",
            "Content-type": `multipart/form-data;`,
            Accept: "application/json",
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then((res) => {
          this.myAddress = res.data.data;
        });
    },
    // ----------------------------------------------------------------
    // send order
    orderMethos() {
      const submitButton = this.$refs["update"];
      submitButton.classList.add("click");
      const data = new FormData();
      data.append("address_id", this.placeId);
      data.append("payment_method", "cash");
      // if (this.copounVal != "") {
      //   data.append("coupon_code", this.copounVal);
      // }
      axios
        .post("/create-order", data, {
          headers: {
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            lang: localStorage.getItem("shop_lang"),
            "cache-control": "no-cache",
            "Content-type": `multipart/form-data;`,
            Accept: "application/json",
          },
        })
        .then(() => {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.successDone = true;
            const cartNumber = document.getElementById("number-cart");
            cartNumber.innerHTML = "0";
          }, 1000);
          setTimeout(() => {
            this.$router.push({ path: "/orders" });
          }, 2000);
        })
        .catch((error) => {
          this.error.static.error = true;
          this.error.static.text = error.response.data.msg;
        });
    },
    // START:: PAYMENT METHOD
    // paymentMethod() {
    //   if (this.transaction_id == undefined) {
    //     window.location.href = `https://knooz.elsaed.aait-d.com/api/payment/${
    //       this.otherData.final_price + this.myAddress.country.shipping_price
    //     }?redirect=${encodeURIComponent("https://knooz-global.com/checkout")}`;
    //   } else if (this.statusPay == "FAILED") {
    //     window.location.href = `https://knooz.elsaed.aait-d.com/api/payment/${
    //       this.otherData.final_price + this.myAddress.country.shipping_price
    //     }?redirect=${encodeURIComponent("https://knooz-global.com/checkout")}`;
    //     // https://knooz-global.com/checkout
    //   } else {
    //     const data = new FormData();
    //     data.append(
    //       "address_id",
    //       localStorage.getItem("knooz-address-selected")
    //     );
    //     data.append("transaction_id", this.transaction_id);
    //     data.append("payment_method", "online");
    //     axios
    //       .post("/create-order", data, {
    //         headers: {
    //           Authorization: "bearer" + localStorage.getItem("shop-token"),
    //           lang: localStorage.getItem("shop_lang"),
    //           "cache-control": "no-cache",
    //           "Content-type": `multipart/form-data;`,
    //           Accept: "application/json",
    //         },
    //       })
    //       .then(() => {
    //         localStorage.removeItem("knooz-address-selected");
    //         setTimeout(() => {
    //           this.successDone = true;
    //           const cartNumber = document.getElementById("number-cart");
    //           cartNumber.innerHTML = "0";
    //         }, 1000);
    //         setTimeout(() => {
    //           this.$router.push({ path: "/orders" });
    //         }, 5000);
    //       })
    //       .catch((error) => {
    //         this.error.static.error = true;
    //         this.error.static.text = error.response.data.msg;
    //       });
    //   }
    // },
    Stripepayment() {
      const data = new FormData();
      data.append(
        "success_url",
        `https://knooz-global.com/pay_success`
        // "success_url",
        // `http://localhost:8080/chat/${this.userId}/success`
      );
      data.append(
        "cancel_url",
        `https://knooz-global.com/pay_cancel`
        // "cancel_url",
        // `http://localhost:8080/chat/${this.userId}/cancled`
      );
      data.append("address_id", localStorage.getItem("knooz-address-selected"));
      data.append(
        "amount",
        this.otherData?.final_price + this.myAddress?.country?.shipping_price
      );
      data.append("payment_method", "online");

      axios
        .post("general/generate_session_id_front", data, {
          headers: {
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            "cache-control": "no-cache",
            Accept: "application/json",
            "Content-type": `multipart/form-data;`,
            "Accept-language": localStorage.getItem("shop-token"),
          },
        })
        .then((res) => {
          this.sessionId = res.data.data.id;
          this.publishableKey = res.data.data?.publishable_key;
          this.stripe_session_data.success_url = `https://knooz-global.com/pay_sucess`;
          this.stripe_session_data.cancel_url = `https://knooz-global.com/pay_cancel`;
          // this.stripe_session_data.success_url = `http://localhost:8080/chat/${this.userId}/success`;
          // this.stripe_session_data.cancel_url = `http://localhost:8080/chat/${this.userId}/cancled`;
          this.stripe_session_datapayment_method_types = ["card"];
          this.stripe_session_dataline_items = [
            {
              price: res.data.data.product_price,
              quantity: 1,
            },
          ];
          this.stripe_session_datamode = "payment";
          // START:: REDIRECT TO CHECKOUT
          this.$refs.checkoutRef.redirectToCheckout();
          // END:: REDIRECT TO CHECKOUT
          this.waiting_payment = false;
        });
    },
    paymentMethod() {
      const data = new FormData();
      data.append("address_id", localStorage.getItem("knooz-address-selected"));
      data.append("transaction_id", this.transaction_id);
      data.append("payment_method", "cash");
      axios
        .post("/create-order", data, {
          headers: {
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            lang: localStorage.getItem("shop_lang"),
            "cache-control": "no-cache",
            "Content-type": `multipart/form-data;`,
            Accept: "application/json",
          },
        })
        .then(() => {
          localStorage.removeItem("knooz-address-selected");
          setTimeout(() => {
            this.successDone = true;
            const cartNumber = document.getElementById("number-cart");
            cartNumber.innerHTML = "0";
          }, 1000);
          setTimeout(() => {
            this.$router.push({ path: "/orders" });
          }, 5000);
        })
        .catch((error) => {
          this.error.static.error = true;
          this.error.static.text = error.response.data.msg;
        });
    },
    // END:: PAYMENT METHOD

    // ----------------------------------------------------------------
    prev() {
      this.step--;
    },
    next() {
      const submitButton = this.$refs["next"];
      submitButton.classList.add("click");
      if (this.placeId != "") {
        this.step++;
      } else {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.error.static.error = true;
          if (this.lang == "ar") {
            this.error.static.text = "يرجى اختيار عنوان التوصيل اولا";
          } else {
            this.error.static.text = "Please Choose DELIVER Address First";
          }
        }, 1000);
      }
    },
    submit() {
      alert("Form Is Submitted.");
    },
  },
  mounted() {
    this.getAddresses();
    this.getCart();
    if (this.lang == "en") {
      this.mainText = textEn;
    } else {
      this.mainText = textAr;
    }
    if (this.transaction_id != undefined) {
      this.modalIsOpen = true;
      this.paymentMethod();
    }
    if (this.statusPay == "FAILED") {
      this.modalIsOpen = true;
    }
  },
  components: {
    loader,
    StripeCheckout,
    // NotFound,
  },
};
</script>

<style scoped></style>
