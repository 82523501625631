<template>
  <div>
    <!--start page-banner-->

    <section class="page-banner">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <!--breadcrumbs-->
            <div class="breadcrumbs">
              <h3 v-if="lang == 'en'">Contact Us</h3>
              <h3 v-if="lang == 'ar'">تواصل معنا</h3>
              <ul class="list-unstyled">
                <li v-if="lang == 'en'">
                  <router-link to="/">Home </router-link>>
                </li>
                <li v-if="lang == 'ar'">
                  <router-link to="/">الرئيسية </router-link>>
                </li>
                <li v-if="lang == 'en'">Contact Us</li>
                <li v-if="lang == 'ar'">تواصل معنا</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- <button type="button" @click="btnLocation">aaaaaa</button> -->
    <!--end page-banner-->
    <!--start contact-us-->
    <Loader v-if="loading" class="fadeIn"></Loader>
    <section class="contact-us fadeIn" v-else>
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="main-title">
              <h3 v-if="lang == 'en'">Get In Touch</h3>
              <h3 v-if="lang == 'ar'">تواصل معنا</h3>
            </div>
          </div>
          <div class="col-md-6">
            <div class="my-form contact-form">
              <div class="get-touch">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="touch-way">
                      <div class="icon">
                        <i class="fas fa-map-marker-alt"></i>
                      </div>
                      <p>Address: {{ allData.address }}</p>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="touch-way">
                      <div class="icon">
                        <i class="fas fa-phone"></i>
                      </div>
                      <p>
                        <a :href="`tel:` + allData.phone">{{
                          allData.phone
                        }}</a>
                      </p>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="touch-way">
                      <div class="icon">
                        <i class="fab fa-whatsapp"></i>
                      </div>
                      <p>
                        <a
                          :href="
                            `https://api.whatsapp.com/send?phone=` +
                            allData.whatsapp
                          "
                          >{{ allData.whatsapp }}</a
                        >
                      </p>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="touch-way">
                      <div class="icon">
                        <i class="fas fa-envelope"></i>
                      </div>
                      <p>
                        <a :href="`mailto:` + allData.email">{{
                          allData.email
                        }}</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="my-form contact-form" style="margin-top: 0">
              <div class="sign-form">
                <form>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <input
                          type="text"
                          name="name"
                          v-if="lang == 'ar'"
                          v-model="data.name"
                          placeholder="الإسم بالكامل"
                          class="form-control"
                        />
                        <input
                          type="text"
                          name="name"
                          v-if="lang == 'en'"
                          v-model="data.name"
                          placeholder="Full Name"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <input
                          type="email"
                          name="email"
                          v-if="lang == 'ar'"
                          v-model="data.email"
                          placeholder="البريد الإلكتروني"
                          class="form-control"
                        />
                        <input
                          type="email"
                          name="email"
                          v-if="lang == 'en'"
                          v-model="data.email"
                          placeholder="Email"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <input
                          type="tel"
                          name="phone"
                          v-if="lang == 'ar'"
                          v-model="data.phone"
                          placeholder="رقم الموبايل"
                          class="form-control"
                        />
                        <input
                          type="tel"
                          name="phone"
                          v-if="lang == 'en'"
                          v-model="data.phone"
                          placeholder="Mobile Number"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <textarea
                          v-if="lang == 'ar'"
                          v-model="data.desc"
                          placeholder="الرسالة"
                          class="form-control text-area-contact"
                        ></textarea>
                        <textarea
                          v-if="lang == 'en'"
                          v-model="data.desc"
                          placeholder="Message"
                          class="form-control text-area-contact"
                        ></textarea>
                      </div>
                    </div>

                    <div class="col-sm-12">
                      <div
                        class="text-center mb-3"
                        style="margin: 0 auto z-index: 8"
                      >
                        <button
                          @click="contactBtn()"
                          type="button"
                          class="button btn main-btn"
                          v-if="lang == 'ar'"
                        >
                          إرسال
                        </button>
                        <button
                          @click="contactBtn()"
                          type="button"
                          class="button btn main-btn"
                          v-if="lang == 'en'"
                        >
                          Send
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div id="map" class="map-section">
        <GmapMap
          :center="mapPosition"
          :zoom="7"
          map-type-id="terrain"
          style="width: 100%; height: 100%"
        >
          <GmapMarker
            :key="index"
            v-for="(m, index) in markers"
            :position="m.position"
            :clickable="true"
            :draggable="true"
            @click="center = m.position"
          />
        </GmapMap>
      </div> -->
      <div class="container">
        <div class="row"></div>
      </div>
    </section>
    <!-- <button @click="getLocation()">Get Location</button>
    <p id="demo"></p> -->
    <!-- error alert -->
    <transition name="slide-fade">
      <div class="error-alert" v-if="error.static.error">
        <span @click="error.static.error = false">
          <i class="fas fa-times"></i>
        </span>
        <p>{{ error.static.text }}</p>
      </div>
    </transition>
    <!-- success alert -->
    <transition name="slide-fade">
      <div class="success-alert" v-if="success">
        <span @click="success = false"> <i class="fas fa-times"></i> </span>
        <p>{{ successText }}</p>
      </div>
    </transition>

    <!--end contact-us-->
  </div>
</template>

<script>
import axios from "axios";
import Loader from "../Loading/Loading";
export default {
  data() {
    return {
      loading: false,
      allData: "",
      id: this.$route.params.id,
      lang: localStorage.getItem("shop_lang"),
      contact_background: "",
      mapPosition: {
        lat: null,
        lng: null,
      },
      data: {
        name: "",
        phone: "",
        email: "",
        desc: "",
        product_id: this.$route.params.id,
      },
      success: false,
      // error
      error: {
        static: {
          error: false,
          text: "",
        },
      },
    };
  },
  components: {
    Loader,
  },

  methods: {
    getLocation() {
      let locationTest = document.getElementById("demo");
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.showPosition);
      } else {
        locationTest.innerHTML =
          "Geolocation is not supported by this browser.";
      }
    },

    showPosition(position) {
      console.log(position);
      let locationTest = document.getElementById("demo");
      locationTest.innerHTML =
        "Latitude: " +
        position.coords.latitude +
        "<br>Longitude: " +
        position.coords.longitude;
    },
    btnLocation() {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          console.log(position.coords.latitude);
          console.log(position.coords.longitude);
          console.log(position);
        },
        function (error) {
          console.log("The Locator was denied. :(");
        }
      );
    },
    contactBtn() {
      this.waiting = true;
      this.error.static.error = false;

      if (this.data.name == "") {
        if (this.lang == "ar") {
          setTimeout(() => {
            this.error.static.error = true;
            this.error.static.text = "يرجي إدخال إسمك بالكامل";
            this.waiting = false;
            this.success = false;
          }, 1000);
        } else {
          setTimeout(() => {
            this.error.static.error = true;
            this.error.static.text = "Please Enter Your Name";
            this.waiting = false;
            this.success = false;
          }, 1000);
        }
      } else if (this.data.phone == "") {
        if (this.lang == "ar") {
          setTimeout(() => {
            this.error.static.error = true;
            this.error.static.text = "يرجي إدخال رقم الموبايل";
            this.waiting = false;
            this.success = false;
          }, 1000);
        } else {
          setTimeout(() => {
            this.error.static.error = true;
            this.error.static.text = "Please Enter Your Email";
            this.waiting = false;
            this.success = false;
          }, 1000);
        }
      } else {
        this.error.static.error = false;
        const data = new FormData();
        // ===== Name
        // data.append("product_id", );
        data.append("name", this.data.name);
        data.append("email", this.data.email);
        data.append("phone", this.data.phone);
        data.append("desc", this.data.desc);
        console.log(data);
        axios
          .post("/general/contact", data, {
            headers: {
              Authorization: "bearer" + localStorage.getItem("shop-token"),
              "cache-control": "no-cache",
              Accept: "application/json",
            },
          })
          .then(() => {
            this.waiting = false;
            this.error.static.error = false;
            this.success = true;
            this.successText = "تم إرسال طلبك بنجاح";
            setTimeout(() => {
              this.showPop = false;
              this.success = false;
            }, 1000);
          })
          .catch((error) => {
            this.error.static.error = true;
            this.error.static.text = error.response.data.msg;
            if (error.response.status == 401) {
              localStorage.removeItem("shop-dashboard-token");
              localStorage.removeItem("shop-dashboard-name");
              this.$router.push({ name: "login" });
            }
            console.log(this.error);
          })
          .finally(() => (this.waiting = false));
      }
    },
    getData() {
      this.loading = true;
      axios
        .get("general/settings", {
          headers: {
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then((res) => {
          this.loading = false;
          this.allData = res.data.data.contact_us;
          this.contact_background = res.data.data.contact_background;
          this.mapPosition.lat = parseFloat(this.allData.lat);
          this.mapPosition.lng = parseFloat(this.allData.lng);
        })
        .finally(() => (this.loading = false));
    },
  },
  mounted() {
    this.c1 = this.$refs.c1;
    this.c2 = this.$refs.c2;
    this.getData();
  },
};
</script>
