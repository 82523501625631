<template>
  <div>
    <div class="signin special-padding forget-password">
      <div class="container">
        <div class="row justify-content-center align-self-center">
          <div class="col-md-6">
            <div class="sign-form">
              <div class="logo">
                <router-link to="/" tag="a">
                  <img class="img-responsive" src="@/assets/images/logo.png" />
                </router-link>
                <p v-if="lang == 'en'">Forget Your Password?</p>
                <p v-if="lang == 'ar'">هل نسيت كلمة المرور ؟</p>
                <span v-if="lang == 'en'">Please Enter Your New Password </span>
                <span v-if="lang == 'ar'"
                  >من فضلك ادخل كلمة المرور الجديدة ؟
                </span>
              </div>
              <form>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        :placeholder="mainText.login.form.password"
                        v-model="user.new_password"
                        v-on:keydown.enter.prevent="changePassword"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        :placeholder="mainText.register.form.confirmPassword"
                        v-model="user.new_password_confirmation"
                        v-on:keydown.enter.prevent="changePassword"
                      />
                    </div>
                  </div>
                </div>
                <div class="bottom-sign">
                  <button
                    type="button"
                    ref="forget"
                    class="btn main-btn confirm_pass"
                    @click="changePassword"
                  >
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="shape">
        <img src="@/assets/images/shapes/shape2.png" />
      </div>
    </div>
    <transition name="slide-fade">
      <div class="error-alert" v-if="error.static.error">
        <span @click="error.static.error = false">
          <i class="fas fa-times"></i>
        </span>
        <p>{{ error.static.text }}</p>
      </div>
    </transition>
    <!-- success alert -->
    <transition name="slide-fade">
      <div class="success-alert" v-if="success">
        <span @click="success = false"> <i class="fas fa-times"></i> </span>
        <p>The verification code sent successfully</p>
      </div>
    </transition>
    <!--end signin-->
  </div>
</template>

<script>
import axios from "axios";
import textAr from "../../lang/ar.json";
import textEn from "../../lang/en.json";
export default {
  name: "change-password",
  data() {
    return {
      user: {
        new_password: "",
        new_password_confirmation: "",
      },
      error: {
        errorEmail: false,
        static: {
          error: false,
          text: "",
        },
      },
      mainText: "",
      success: false,
      lang: localStorage.getItem("shop_lang"),
      phone: localStorage.getItem("shop-phone"),
    };
  },
  methods: {
    // login
    changePassword() {
      const submitButton = this.$refs["forget"];
      submitButton.classList.add("click");
      this.error.errorEmail = false;
      this.error.static.error = false;

      const data = new FormData();
      data.append("phone", this.phone);
      data.append("new_password", this.user.new_password);
      data.append(
        "new_password_confirmation",
        this.user.new_password_confirmation
      );
      axios
        .post("/auth/password/reset", data, {
          headers: {
            "cache-control": "no-cache",
            "Content-type": `multipart/form-data;`,
            Accept: "application/json",
            lang: this.lang,
          },
        })
        .then(() => {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.success = true;
          }, 1000);
          setTimeout(() => {
            this.$router.push({ path: "/login" });
          }, 2000);
        })
        .catch((error) => {
          this.error.static.error = true;
          submitButton.classList.remove("click");
          this.waiting = false;
          this.error.errorEmail = false;
          this.error.static.text = error.response.data.msg;
        })
        .finally(() => (this.waiting = false));
    },
  },
  mounted() {
    var userType = localStorage.getItem("shop-token");
    if (userType) {
      this.$router.push({ path: "/" });
    }
    if (this.lang == "en") {
      this.mainText = textEn;
    } else {
      this.mainText = textAr;
    }
  },
  components: {},
};
</script>
