<template>
  <div>
    <loader v-if="loading"></loader>
    <div class="orders" v-else>
      <h4 v-if="mainText">{{ mainText.account.menu.auctions }}</h4>
      <NotFound v-if="myAuctions == ''"></NotFound>
      <table class="table" v-else>
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">{{ mainText.account.bids.table.productImage }}</th>
            <th scope="col">{{ mainText.account.bids.table.productName }}</th>
            <th scope="col">{{ mainText.account.bids.table.date }}</th>
            <th scope="col">{{ mainText.account.bids.table.status }}</th>
            <th scope="col">{{ mainText.account.bids.table.yourBidPrice }}</th>
            <th scope="col">{{ mainText.account.bids.table.finalPrice }}</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(bid, index) in myAuctions" :key="bid.id">
            <th scope="row">{{ ++index }}</th>
            <td>
              <div class="order-img">
                <img :src="bid.product.main_image" v-if="bid.product.name" />
              </div>
            </td>
            <td>{{ bid.product.name }}</td>
            <td>{{ bid.end_bid_date }}</td>
            <td v-if="bid.status === 'pending'" class="status pending">
              {{ mainText.account.orders.status.pending }}
            </td>
            <td v-if="bid.status === 'winner'" class="status accepted">
              {{ mainText.account.bids.status.winner }} 
            </td>
            <td v-if="bid.status === 'closed'" class="status cancelled">
              {{ mainText.account.bids.status.closed }}
            </td>
            
            <td><span class="currency"></span> {{ bid.your_bid_price }}</td>
            <td><span class="currency"></span> {{ bid.last_bid_price }}</td>
            <td v-if="bid.status == 'winner' || bid.status == 'pending'">
              <router-link
                :to="'/auction/' + bid.id"
                class="btn main-btn"
                >{{ mainText.account.orders.view }}</router-link
              >
            </td>
            
            
          </tr>
        </tbody>
      </table>
    </div>
    <!-- alert -->
    
    
    <!-- alert -->
    
  </div>
</template>

<script>
import axios from "axios";
import textAr from "../../lang/ar.json";
import textEn from "../../lang/en.json";
import loader from "../../Loading/Loading";
import NotFound from "../../Loading/not-found";
export default {
  data() {
    return {
      myAuctions: "",
      loading: false,
      lang: localStorage.getItem("shop_lang"),
      mainText: "",
      order_id: "",
      alert: {
        alertShow: false,
        alertDone: false,
      },
    };
  },
  methods: {
    getAuctions() {
      this.loading = true;
      axios
        .get("/auctions", {
          headers: {
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            "cache-control": "no-cache",
            Accept: "application/json",
            lang: "en",
          },
        })
        .then((res) => {
          this.loading = false;
          this.myAuctions = res.data.data;
        });
    },
    
  },
  components: {
    loader,
    NotFound,
  },
  mounted() {
    this.getAuctions();
    if (this.lang == "en") {
      this.mainText = textEn;
    } else {
      this.mainText = textAr;
    }
  },
};
</script>
