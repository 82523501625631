<template>
  <!-- loader -->
  <div class="container">
    <div class="row client">
      <div class="col-lg-12">
        <div class="card-loader">
          <div class="animated-background profile-image"></div>
          <div class="small">
            <div class="animated-background prod-name-load"></div>
            <div class="animated-background pro-rate-load"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  mounted() {},
  components: {},
};
</script>
<style scoped>
.client .card-loader .small {
  display: block;
  text-align: right;
}
.client .card-loader {
  margin-top: 20px;
}
.client .profile-image {
  width: 100%;
  height: 250px;
}
.client .animated-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholder;
  animation-timing-function: linear;
  background: #ddd;
  background: linear-gradient(to right, #eee 8%, #ddd 18%, #eee 33%);
  background-size: 1200px 100px;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  border-radius: 100px;
}
.client .small .animated-background {
  min-height: 15px;
  margin: 15px auto;
  height: 20px;
  width: 100px;
  border-radius: 5px;
}
.client .small .animated-background:first-of-type {
  width: 80%;
  margin: 15px auto;
  height: 20px;
  border-radius: 5px;
}
@keyframes placeholder {
  0% {
    background-position: -600px 0;
  }
  100% {
    background-position: 600px 0;
  }
}
</style>
