<template>
  <div>
    <!--start signin-->

    <div class="signin special-padding forget-password">
      <div class="container">
        <div class="row justify-content-center align-self-center">
          <div class="col-md-6">
            <div class="sign-form">
              <div class="logo">
                <router-link to="/" tag="a">
                  <img class="img-responsive" src="@/assets/images/logo.png" />
                </router-link>
                <p v-if="lang == 'en'">Forget Your Password?</p>
                <p v-if="lang == 'ar'">هل نسيت كلمة المرور؟</p>
                <span v-if="lang == 'en'"
                  >Please Enter Your Mobile Number</span
                >
                <span v-if="lang == 'ar'"
                  >يرجى ادخال رقم الهاتف </span
                >
              </div>
              <form>
                <div class="form-group">
                  <input
                    v-if="lang == 'en'"
                    type="tel"
                    class="form-control"
                    placeholder="Email Phone"
                    v-model="user.phone"
                    v-on:keydown.enter.prevent="forget"
                  />
                  <input
                    v-if="lang == 'ar'"
                    type="tel"
                    class="form-control"
                    placeholder="ادخل رقم الهاتف"
                    v-model="user.phone"
                    v-on:keydown.enter.prevent="forget"
                  />
                  <!-- erro email -->
                  <div
                    class="errorText email fadeIn"
                    v-if="error.errorEmail"
                  ></div>
                </div>
                <div class="bottom-sign">
                  <button
                    type="button"
                    ref="forget"
                    @click="forget"
                    class="dark-button forget-button button main-btn"
                  >
                    <i class="fas fa-spinner"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="shape">
        <img src="@/assets/images/shapes/shape2.png" />
      </div>
    </div>
    <transition name="slide-fade">
      <div class="error-alert" v-if="error.static.error">
        <span @click="error.static.error = false">
          <i class="fas fa-times"></i>
        </span>
        <p>{{ error.static.text }}</p>
      </div>
    </transition>
    <!-- success alert -->
    <transition name="slide-fade">
      <div class="success-alert" v-if="success">
        <span @click="success = false"> <i class="fas fa-times"></i> </span>
        <p>The verification code sent successfully</p>
      </div>
    </transition>
    <!--end signin-->
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "enter-email",
  data() {
    return {
      user: {
        email: "",
      },
      error: {
        errorEmail: false,
        static: {
          error: false,
          text: "",
        },
      },
      success: false,
      lang: localStorage.getItem("shop_lang"),
    };
  },
  methods: {
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    // login
    forget() {
      const submitButton = this.$refs["forget"];
      submitButton.classList.add("click");
      this.error.errorEmail = false;
      this.error.static.error = false;
      if (this.user.phone == "") {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.error.errorEmail = true;
        }, 1000);
      } else {
        const data = new FormData();
        data.append("phone", this.user.phone);
        axios
          .post("/auth/password/forget", data, {
            headers: {
              "cache-control": "no-cache",
              "Content-type": `multipart/form-data;`,
              Accept: "application/json",
              lang: "en",
            },
          })
          .then(() => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.success = true;
              localStorage.setItem("shop-phone", this.user.phone);
            }, 1000);
            setTimeout(() => {
              this.$router.push({ path: "/change-password" });
            }, 2000);
          })
          .catch((error) => {
            this.error.static.error = true;
            submitButton.classList.remove("click");
            this.waiting = false;
            this.error.errorEmail = false;
            this.error.static.text = error.response.data.msg;
          })
          .finally(() => (this.waiting = false));
      }
    },
  },
  mounted() {
    var userType = localStorage.getItem("shop-token");
    if (userType) {
      this.$router.push({ path: "/" });
    }
  },
  components: {},
};
</script>
