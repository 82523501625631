<template>
  <!--start Best Selling-->
  <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
  <div>
    <!--start page-banner-->
    <div class="page-banner">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <!--breadcrumbs-->
            <div class="breadcrumbs">
              <h3 v-if="lang == 'en'">Best Offers</h3>
              <h3 v-if="lang == 'ar'">افضل العروض</h3>
              <ul class="list-unstyled">
                <li v-if="lang == 'en'">
                  <router-link to="/" tag="a">Home </router-link> >
                </li>
                <li v-if="lang == 'ar'">
                  <router-link to="/" tag="a">الرئيسية </router-link> >
                </li>
                <li v-if="lang == 'en'">Best Offers</li>
                <li v-if="lang == 'ar'">افضل العروض</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="exclusive-products products">
      <div class="container">
        <Loader v-if="$store.state.cardsLoading"></Loader>
        <div class="row fadeIn" v-else>
          <!-- tab cotent -->
          <div class="col-sm-12 fadeIn" v-if="$store.state.allBestOffers == ''">
            <NotFound></NotFound>
          </div>
          <div
            class="col-6 col-lg-3 col-md-6 fadeIn"
            v-for="item in $store.state.allBestOffers"
            :key="item.id"
            v-else
          >
            <div class="one-product">
              <!-- product image-->
              <router-link :to="'/product/' + item.id">
                <div class="product-img">
                  <img v-lazy="item.main_image" />
                  <img class="hover_image" v-lazy="item.hover_image" />
                </div>
              </router-link>
              <!-- product description -->
              <div class="product-exp">
                <!-- product title -->
                <div class="product-title">
                  <h5>{{ item.name }}</h5>
                  <span>{{ item.brand_name }}</span>
                </div>
                <!-- product add , show and like -->
                <div class="price-fav">
                  <div class="price">
                    <span class="new-price" v-if="item.is_offered != 0"
                      ><span class="currency"></span>
                      {{ item.offer_price }}</span
                    >
                    <span class="new-price" v-if="item.is_offered == 0"
                      ><span class="currency"></span> {{ item.price }}</span
                    >
                    <span class="old-price" v-if="item.is_offered != 0"
                      ><span class="currency"></span> {{ item.price }}</span
                    >
                  </div>
                  <ul class="list-unstyled" v-if="token">
                    <li class="like">
                      <button
                        @click="
                          $set(item, 'isActive', !item.isActive),
                            doLike(item.id)
                        "
                        v-if="item.is_favourite == false"
                        :class="[
                          'btn',
                          { likee: item.isActive },
                          { dislikee: !item.isActive },
                        ]"
                      ></button>
                      <!-- like -->
                      <button
                        @click="
                          $set(item, 'isActive', !item.isActive),
                            doLike(item.id)
                        "
                        v-else
                        :class="[
                          'btn',
                          { likee: !item.isActive },
                          { dislikee: item.isActive },
                        ]"
                      ></button>
                    </li>
                  </ul>
                </div>
              </div>
              <span class="pro-stat" v-if="item.is_offered != 0">
                <span v-if="lang == 'en'">Sale !</span>
                <span v-else>تخفيض !</span>
              </span>
            </div>
            <div class="text-center mb-5">
              <router-link
                :to="'/product/' + item.id"
                class="dark-button button btn main-btn home-btn"
              >
                <span v-if="lang == 'en'">Select Option</span>
                <span v-if="lang == 'ar'">حدد خيار</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--end Best-selling-->
</template>

<script>
import axios from "axios";
import Loader from "./../Loading/cards";
import NotFound from "./../Loading/not-found";
export default {
  data() {
    return {
      tabText: "all",
      loading: false,
      // $store.state.allBestOffers: "",
      allCategories: "",
      token: localStorage.getItem("shop-token"),
      lang: localStorage.getItem("shop_lang"),
    };
  },
  mounted() {
    this.getCategorioes();
    this.$store.dispatch("bestOffers");
  },
  methods: {
    tabClick(val) {
      this.tabText = val;
    },
    getCategorioes() {
      axios
        .get("/general/all-categories", {
          headers: {
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then((res) => {
          this.allCategories = res.data.data;
        });
    },
    doLike(id) {
      this.$store.dispatch("addFavourite", { id });
    },
  },
  components: {
    Loader,
    NotFound,
  },
};
</script>
