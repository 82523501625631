var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"best-selling products"},[_c('div',{staticClass:"container"},[(_vm.mainText)?_c('div',{staticClass:"main-title"},[_c('h3',[_vm._v(_vm._s(_vm.mainText.home.titles.lowest_price))])]):_vm._e(),(_vm.$store.state.cardsLoading)?_c('Loader',{staticClass:"fadeIn"}):_c('div',{staticClass:"row fadeIn"},[(_vm.$store.state.homeData.lowest_price == '')?_c('NotFound'):_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"best-carousel"},[_c('VueSlickCarousel',_vm._b({staticClass:"bes-car",on:{"beforeChange":_vm.onBeforeChangeSlider1}},'VueSlickCarousel',_vm.slider2Setting,false),_vm._l((_vm.$store.state.homeData.lowest_price),function(item){return _c('div',{key:item.id,staticClass:"item"},[_c('div',{staticClass:"one-product"},[_c('router-link',{attrs:{"to":'/product/' + item.id}},[_c('div',{staticClass:"product-img"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.main_image),expression:"item.main_image"}]}),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.hover_image),expression:"item.hover_image"}],staticClass:"hover_image"})])]),_c('div',{staticClass:"product-exp"},[_c('div',{staticClass:"product-title"},[_c('h5',[_vm._v(_vm._s(item.name))]),_c('span',[_vm._v(" "+_vm._s(item.brand_name)+" ")])]),_c('div',{staticClass:"price-fav"},[_c('div',{staticClass:"price"},[(item.is_offered != 0)?_c('span',{staticClass:"new-price"},[_c('span',{staticClass:"currency"}),_vm._v(" "+_vm._s(item.offer_price))]):_vm._e(),(item.is_offered == 0)?_c('span',{staticClass:"new-price"},[_c('span',{staticClass:"currency"}),_vm._v(" "+_vm._s(item.price))]):_vm._e(),(item.is_offered != 0)?_c('span',{staticClass:"old-price"},[_c('span',{staticClass:"currency"}),_vm._v(" "+_vm._s(item.price))]):_vm._e()]),(_vm.token)?_c('ul',{staticClass:"list-unstyled"},[_c('li',{staticClass:"like"},[(item.is_favourite == false)?_c('button',{class:[
                            'btn',
                            { likee: item.isActive },
                            { dislikee: !item.isActive } ],on:{"click":function($event){_vm.$set(item, 'isActive', !item.isActive),
                              _vm.doLike(item.id)}}}):_c('button',{class:[
                            'btn',
                            { likee: !item.isActive },
                            { dislikee: item.isActive } ],on:{"click":function($event){_vm.$set(item, 'isActive', !item.isActive),
                              _vm.doLike(item.id)}}})])]):_vm._e()])]),(item.is_offered != 0)?_c('span',{staticClass:"pro-stat"},[(_vm.lang == 'en')?_c('span',[_vm._v("Sale !")]):_c('span',[_vm._v("تخفيض !")])]):_vm._e()],1),_c('div',{staticClass:"text-center"},[_c('router-link',{staticClass:"dark-button button btn main-btn home-btn",attrs:{"to":'/product/' + item.id}},[(_vm.lang == 'en')?_c('span',[_vm._v("Select Option")]):_vm._e(),(_vm.lang == 'ar')?_c('span',[_vm._v("حدد خيار")]):_vm._e()])],1)])}),0)],1)]),(_vm.$store.state.homeData.lowest_price != '' && _vm.mainText)?_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"show-more"},[_c('router-link',{attrs:{"to":"/lowest-price"}},[_vm._v(" "+_vm._s(_vm.mainText.home.seeAll)+" "),_c('span',[_c('img',{attrs:{"src":require("@/assets/images/long-arrow.png")}})])])],1)]):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }