<template>
  <div>
    <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
    <loader v-if="loading" class="fadeIn"></loader>
    <div class="single-product fadeIn" v-else>
      <div class="container" v-if="mainText">
        <LoaderAdd
          v-if="$store.state.productLoader"
          class="fadeIn loader-product"
        ></LoaderAdd>
        <div class="row" v-else>
          <div class="col-md-6">
            <div class="product-slider">
              <!-- <loader v-if="loaderSlider" /> -->
              <div class="slider-for">
                <div class="main-slider-product">
                  <VueSlickCarousel
                    ref="slider1"
                    v-bind="sliderLoop"
                    class="single-colors"
                    @beforeChange="onBeforeChangeSlider1"
                  >
                    <div
                      v-for="single in auctionData.product.images"
                      :key="single.id"
                    >
                      <zoom-on-hover
                        :img-normal="single.image"
                        :img-zoom="single.image"
                      >
                      </zoom-on-hover>
                    </div>
                  </VueSlickCarousel>
                </div>

                <div class="sub-slider-product">
                  <VueSlickCarousel
                    ref="slider2"
                    v-bind="slider2Setting"
                    @beforeChange="onBeforeChangeSlider1"
                  >
                    <div
                      v-for="single in auctionData.product.images"
                      :key="single.id"
                    >
                      <img v-lazy="single.image" />
                    </div>
                  </VueSlickCarousel>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="product-details">
              <div class="product_breadcrumb">
                <ul>
                  <li>
                    <router-link to="/">
                      {{ mainText.account.breadcrumbs.home }}
                    </router-link>
                    <i class="fas fa-chevron-right"></i>
                  </li>
                  <li>
                    <router-link
                      :to="/category/ + auctionData.product.category_id"
                    >
                      {{
                        auctionData.product.category_name.substring(0, 15) +
                        "..."
                      }}
                    </router-link>
                    <i class="fas fa-chevron-right"></i>
                  </li>
                  <li>
                    <span>
                      {{ auctionData.product.name.substring(0, 30) + "..." }}
                    </span>
                  </li>
                </ul>
              </div>
              <div class="top-product-details">
                <div class="pro-name">
                  <h3>{{ auctionData.product.name }}</h3>
                </div>
              </div>

              <div class="time_style">
                <i class="far fa-clock"></i>
                {{ auctionData.time_remaining }}
              </div>
              <div class="item-number item-number-auctions d-flex">
                <p>
                  {{ mainText.product.slider.currentPrice }} :
                  <span class="price">
                    <span class="new">
                      <span class="currency"></span>
                      {{ auctionData.last_bid_price }}
                    </span>
                  </span>
                </p>
                <p>
                  {{ mainText.product.slider.bidPrice }} :
                  <span class="price">
                    <span class="new">
                      <span class="currency"></span>
                      {{ auctionData.bid_value }}
                    </span>
                  </span>
                </p>
              </div>

              <div class="des">
                <p v-html="auctionData.product.description"></p>
              </div>

              <div class="time_style">
                <i class="fas fa-gavel"></i>
                {{ auctionData.bidders_count }}
              </div>

              <div class="user-rating">
                <ul class="list-unstyled">
                  <li
                    class="full-star"
                    v-for="star in auctionData.product.rate"
                    :key="star.id"
                  >
                    <span><i class="fas fa-star"></i></span>
                  </li>
                  <li
                    class="gray-star"
                    v-for="star in stars - auctionData.product.rate"
                    :key="star.id"
                  >
                    <span><i class="fas fa-star"></i></span>
                  </li>
                </ul>

                <h5>
                  {{ auctionData.product.reviews.length }}
                  {{ mainText.product.slider.customerReviews }}
                </h5>
              </div>

              <div
                class="counter-add-cart"
                v-if="
                  auctionData.status == 'pending' || auctionData.status == ''
                "
              >
                <div class="input-group counter">
                  <div class="input-group-minus">
                    <button
                      class="input-group-text btn-minus"
                      @click="counterMinus"
                    >
                      -
                    </button>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    v-model="counter"
                    min="0"
                  />
                  <div class="input-group-plus">
                    <button
                      class="input-group-text btn-plus"
                      @click="counterAdd"
                    >
                      +
                    </button>
                  </div>
                </div>

                <button
                  type="button"
                  ref="add"
                  @click="addBid()"
                  class="dark-button button btn main-btn add-bid"
                >
                  <i class="fas fa-spinner"></i>
                </button>
              </div>
              <div
                class="counter-add-cart"
                v-if="auctionData.status == 'winner'"
              >
                <router-link
                  to="/contact-us"
                  class="dark-button button btn main-btn"
                >
                  <span v-if="lang == 'ar'">تواصل معنا</span>
                  <span v-else>Contact Us</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- error alert -->
    <transition name="slide-fade">
      <div class="error-alert" v-if="error.static.error">
        <span @click="error.static.error = false">
          <i class="fas fa-times"></i>
        </span>
        <p>{{ error.static.text }}</p>
      </div>
    </transition>
    <!-- success alert -->
    <transition name="slide-fade">
      <div class="success-alert" v-if="success">
        <span @click="success = false"> <i class="fas fa-times"></i> </span>
        <p>{{ successText }}</p>
      </div>
    </transition>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import axios from "axios";
import loader from "../../Loading/main-slider";
import LoaderAdd from "../../Loading/Loading";
import textAr from "../../lang/ar.json";
import textEn from "../../lang/en.json";
export default {
  data() {
    return {
      items: [1, 2, 3, 4, 5],
      loading: false,
      rate: "",
      stars: 5,

      loaderSlider: false,
      counter: 0,

      auctionData: {
        status: "",
      },
      valueName: "",
      id: this.$route.params.id,
      successText: "",
      // error
      error: {
        static: {
          error: false,
          text: "",
        },
      },
      pageLocation: window.location.href,
      showShare: false,
      success: false,
      token: localStorage.getItem("shop-token"),
      lang: localStorage.getItem("shop_lang"),
      mainText: "",
      slider1Setting: {
        arrows: false,
        dots: false,
        asNavFor: this.$refs.slider2,
        focusOnSelect: true,
      },

      sliderLoop: {
        arrows: true,
        dots: false,
        // asNavFor: this.$refs.slider1,
        focusOnSelect: true,
      },
      slider2Setting: {
        arrows: true,
        dots: false,
        asNavFor: this.$refs.slider1,
        focusOnSelect: true,
        slidesToShow: 4,
        draggable: false,
      },
      slider22Setting: {
        arrows: true,
        dots: false,
        asNavFor: this.$refs.slider1,
        focusOnSelect: true,
        slidesToShow: 12,
        draggable: false,
      },
    };
  },

  components: {
    VueSlickCarousel,
    loader,
    LoaderAdd,
  },

  methods: {
    closedFun() {
      if (this.auctionData.status == "closed") {
        this.$router.push({ path: "/" });
      }
    },
    onBeforeChangeSlider1(currentPosition, nextPosition) {
      this.$refs.slider1.goTo(nextPosition);
      this.$refs.slider2.goTo(nextPosition);
    },
    onBeforeChangeSlider2(currentPosition, nextPosition) {
      this.$refs.slider1.goTo(nextPosition);
      this.$refs.slider2.goTo(nextPosition);
    },
    onBeforeChangeSlider3(currentPosition, nextPosition) {
      this.$refs.slider1.goTo(nextPosition);
      this.$refs.slider2.goTo(nextPosition);
    },
    counterAdd() {
      this.counter += this.auctionData.bid_value;
    },
    counterMinus() {
      let myCounter = this.counter;
      let myLimit = this.auctionData.last_bid_price;
      let theValue = this.auctionData.bid_value;
      myCounter -= theValue;
      if (myCounter < myLimit) {
        this.counter = myLimit;
        this.error.static.error = true;
        this.error.static.text = this.mainText.product.messages.bid_message_limit;
        setTimeout(() => {
          this.error.static.error = false;
        }, 2500);
      } else {
        this.counter = myCounter;
      }
    },
    getData() {
      this.loading = true;
      axios
        .get("/general/auctionDetails/" + this.id, {
          headers: {
            Authorization: "bearer" + localStorage.getItem("shop-token"),
            "cache-control": "no-cache",
            "Content-type": "application/json",
            Accept: "application/json",
            lang: localStorage.getItem("shop_lang"),
          },
        })
        .then((res) => {
          this.loading = false;
          this.auctionData = res.data.data;
          this.counter = res.data.data.last_bid_price;
          this.closedFun();
        })
        .finally(() => (this.loading = false));
    },
    addBid() {
      if (!this.token) {
        if (this.lang == "ar") {
          this.error.static.error = true;
          this.error.static.text = "يجب عليك تسجيل الدخول اولا";
          setTimeout(() => {
            this.error.static.error = false;
          }, 3000);
        } else {
          this.error.static.error = true;
          this.error.static.text = "You Must Login";
          setTimeout(() => {
            this.error.static.error = false;
          }, 3000);
        }
      } else {
        this.error.static.error = false;
        this.success = false;
        const submitButton = this.$refs["add"];
        submitButton.classList.add("click");

        const data = new FormData();
        data.append("auction_id", this.id);
        data.append("last_bid_price", this.counter);

        axios
          .post("/auctions", data, {
            headers: {
              Authorization: "bearer" + localStorage.getItem("shop-token"),
              "cache-control": "no-cache",
              "Content-type": "application/json",
              Accept: "application/json",
              lang: localStorage.getItem("shop_lang"),
            },
          })
          .then((res) => {
            this.success = true;
            this.successText = res.data.msg;
            this.getData();
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.success = false;
            }, 3000);
          })
          .catch((error) => {
            this.error.static.error = true;
            this.error.static.text = error.response.data.msg;
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.error.static.error = false;
            }, 1000);
          });
      }
    },
  },

  mounted() {
    this.getData();
    if (this.lang == "en") {
      this.mainText = textEn;
    } else {
      this.mainText = textAr;
    }
  },
};
</script>
